import i18n from '@/libs/i18n'
import authApi from '../../api/auth-api'
import router from '../../router'
import clients from '@/store/clients'
import clientsApi from '@/api/clients-api'

const state = {
  loginFailed: false,
  user: {},
  token: {},
  userMe: {},
}

const mutations = {
  recoverUser(state) {
    const recoveredUser = JSON.parse(localStorage.getItem('userData'))
    if (!recoveredUser) {
      state.status = null
      state.user = null
    } else {
      state.status = { loggedIn: true }
      state.user = recoveredUser
    }
  },
  meSuccess(state, val) {
    state.userMe = val
  },
  logout(state) {
    state.status = {}
    state.token = {}
    state.user = null
  },
  refreshTokenSuccess(state, responseData) {
    const recoveredToken = JSON.parse(localStorage.getItem('token'))
    recoveredToken.token = responseData.access_token
    recoveredToken.expires_in = new Date(
        new Date().getTime() + responseData.expires_in * 1000,
    ).getTime()
    localStorage.setItem('token', JSON.stringify(recoveredToken))
  },
  loginSuccess(state, responseData) {
    state.status = { loggedIn: true }
    state.token = {
      token: responseData.access_token,
      token_type: responseData.token_type,
      expires_in: new Date(
          new Date().getTime() + responseData.expires_in * 1000,
      ).getTime(),
    }
    state.user = {
      id: responseData.user_data.id,
      lang: responseData.user_data.lang,
      name: responseData.user_data.name,
      surname: responseData.user_data.surname,
      companies: responseData.user_data.companies,
      clients: responseData.user_data.clients,
      departaments: responseData.user_data.departaments,
      avatar: responseData.user_data.avatar,
      roles: responseData.user_data.roles,
      email: responseData.user_data.email,
    }
    state.loginFailed = false

    localStorage.setItem('userData', JSON.stringify(state.user))
    localStorage.setItem('token', JSON.stringify(state.token))
  },
  loginFailure(state) {
    state.status = {}
    state.token = {}
    state.user = null
    state.loginFailed = true
  },
}

const actions = {
  login({ commit, dispatch }, { email, password, client = '' }) {
    commit("app/loading", true, { root: true });
    authApi.login(email, password, client).then(
        async (response) => {
          if (
              response.data.user_data.roles[0].name === 'prl_cliente'
              || response.data.user_data.roles[0].name === 'operario_cliente'
          ) {
            commit('loginFailure', {})
            commit('app/loading', false, { root: true })
            commit(
                'notifications',
                {
                  title: i18n.t('NotValid', { nombre: i18n.t('Usuario') }),
                  variant: 'danger',
                },
                { root: true },
            )
            commit('setShowNotifications', true, { root: true })
          } else {
            commit('loginSuccess', response.data)
            commit('notifications', { title: 'Welcome' }, { root: true })
            if (
                response.data.user_data.roles[0].name === 'admin_cliente'
            ) {
              try {
                localStorage.setItem('currentClient', JSON.stringify(response.data.user_data.clients[0]))
                await clientsApi.getLists(response.data.user_data.clients[0].id).then(
                    response => {
                      localStorage.setItem('typesAvailables', JSON.stringify(response.data))
                      router.push({ name: 'assets' }, () => {
                        commit('app/loading', false, { root: true })
                        commit('setShowNotifications', true, { root: true })
                      })
                    },
                    () => {
                      localStorage.setItem('typesAvailables', JSON.stringify({}))
                      location.reload()
                    }
                )
                // Aquí puedes continuar con el código que deseas ejecutar después de que setCurrentClient se complete
              } catch (error) {
                console.error("Ocurrió un error al establecer el cliente actual:", error);
              }
            }else{
              router.push({ name: 'dashboard' }, () => {
                commit('app/loading', false, { root: true })
                commit('setShowNotifications', true, { root: true })
              })
            }


          }
        },
        error => {
          commit('loginFailure', error)
          commit('app/loading', false, { root: true })
          if (error.response.data.error && error.response.data.error === 'User not verified') {
            commit('notifications', { title: i18n.t('ErrorUserNotVerified'), variant: 'danger' }, { root: true })
          } else if (error.response.data.error && error.response.data.error === 'User not active') {
            commit('notifications', { title: i18n.t('ErrorUserNotActive'), variant: 'danger' }, { root: true })
          } else if (error.response.data.error && error.response.data.error === 'Client not active') {
            commit('notifications', { title: 'Tu usuario ha sido validado. Podrás acceder a la plataforma cuando Tecnitex valide la empresa.', variant: 'danger' }, { root: true })
          } else if (error.response.data.error && error.response.data.error === 'Client not exist') {
            commit('notifications', { title: 'Cliente incorrecto', variant: 'danger' }, { root: true })
          } else if (error.response.data.error && error.response.data.error === 'Username not exist') {
            commit('notifications', { title: 'Nombre de usuario incorrecto', variant: 'danger' }, { root: true })
          } else {
            commit('notifications', { title: i18n.t('ErrorLogin'), variant: 'danger' }, { root: true })
          }
          commit('setShowNotifications', true, { root: true })
        },
    )
  },
  /* updateDataLogin (reload = false) {
    authApi.me()
      .then(
        response => {
          const recoveredUser = JSON.parse(localStorage.getItem('user'));

          recoveredUser.lang = response.data.user_data.lang;
          recoveredUser.name = response.data.user_data.name;
          recoveredUser.surname = response.data.user_data.surname;
          recoveredUser.avatar = response.data.user_data.avatar;
          recoveredUser.email = response.data.user_data.email;

          localStorage.setItem('user', JSON.stringify(recoveredUser));

          if (reload) {
            location.reload();
          }
        }
      );
  },
  recoverPassword ({ commit, dispatch }, { email}) {
    commit('loading', true);
    authApi.recoverPassword(email)
      .then(
        response => {
          commit('notifications', {title: 'Hemos enviado un email con las instrucciones. Revise su bandeja de entrada.'});
          commit('loading', false);
          commit('showNotifications', true);
        },
        error => {
          commit('notifications', {title: 'No ha sido posible enviar un email.', color: 'danger'});
          commit('loading', false);
          commit('showNotifications', true);
        }
      );
  },
  resetPassword ({ commit, dispatch }, { email, password, password_confirmation, token}) {
    commit('loading', true);
    authApi.resetPassword(email, password, password_confirmation, token)
      .then(
        response => {
          commit('notifications', {title: 'Contraseña actualizada correctamente.'});
          router.push({ name: 'login'}, function () {
            commit('loading', false);
            commit('showNotifications', true);
          });
        },
        error => {
          commit('notifications', {title: 'No ha sido posible actualizar su contraseña.', color: 'danger'});
          commit('loading', false);
          commit('showNotifications', true);
        }
      );
  }, */
  me({ commit }) {
    authApi.me().then(
        response => {
          commit('meSuccess', response.data)
        },
        () => {
          commit(
              'notifications',
              {
                title: 'No ha sido posible recurar la información.',
                variant: 'danger',
              },
              { root: true },
          )
          commit('setShowNotifications', true, { root: true })
        },
    )
  },
  logout({ commit }) {
    commit('app/loading', true, { root: true })
    commit('clients/removeCurrentClient', '', { root: true })
    commit('companies/removeCurrentCompany', '', { root: true })
    localStorage.removeItem('userData')
    localStorage.removeItem('token')
    localStorage.removeItem('typesAvailables')
    commit('logout')
    commit(
        'notifications',
        { title: i18n.t('SuccessLogout'), variant: 'success' },
        { root: true },
    )
    router.push({ name: 'login' }, () => {
      commit('app/loading', false, { root: true })
      commit('setShowNotifications', true, { root: true })
    })
  },
  checkToken({ commit, dispatch }) {
    const token = JSON.parse(localStorage.getItem('token'))
    const current = new Date().getTime()
    if (!token || current >= token.expires_in) {
      commit(
          'notifications',
          {
            title: 'Sesión expirada, vuelva a iniciar sesión.',
            variant: 'danger',
          },
          { root: true },
      )
      dispatch('logout')
      router.push({ name: 'login' }, () => {
        commit('app/loading', false, { root: true })
        commit('setShowNotifications', true, { root: true })
      })
    } else if (token.expires_in - current < 1800000) {
      dispatch('refreshToken')
    }
  },
  refreshToken({ commit }) {
    authApi.refreshToken().then(response => {
      commit('refreshTokenSuccess', response.data)
    })
  },
}

const getters = {
  getUser: state => state.user,
  getToken: state => state.token,
  getUserMe: state => state.userMe,
  getRole: state => {
    if (state.user && state.user.roles[0]) {
      return state.user.roles[0].name
    }
    return ''
  },
  getAvatar: state => {
    if (state.user.avatar) {
      return state.user.avatar
    }
    return process.env.VUE_APP_IMAGE_PLACEHOLDER
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
