<template>
  <b-card>
    <h4 class="font-weight-bolder">{{ $t("assets") }}</h4>
    <hr class="my-1" />
    <b-card bg-variant="light" no-body>
      <transition-group name="list">
        <div v-for="(asset, index) in assets" :key="index">
          <AssetsCardTable :asset="{ ...asset, index }" />
        </div>
      </transition-group>
    </b-card>

  </b-card>
</template>

<script>
import AssetsCardTable from "@/components/assets/card/AssetsCardTable.vue";

export default {
  props: {
    assets: {
      type: Array,
    },
  },
  components: {
    AssetsCardTable,
  },
};
</script>
