import generalApi from '@/api/general-api'
import i18n from '@/libs/i18n'
import alertsApi from '../../api/alerts-api'
import router from '../../router'

const state = {
    items: [],
    totalItems: 0,
}

const mutations = {
    setItems(state, val) {
        state.items = val || [] // Asegúrate de que val siempre sea un array
    },
    setTotalItems(state, val) {
        state.totalItems = val || 0 // Asegúrate de que val siempre sea un número
    },
}

const actions = {
    async getListAlerts(
        { commit },
        {
            client = '',
            page,
            per_page,
            search = '',
            date_ini = '',
            date_fin = '',
            severity = '',
            status = '',
            type = '',
            solars = '',
            solar_id = '',
            asset_id = '',
            orderBy = '',
        },
    ) {
        commit('app/loading', true, { root: true })
        try {
            const response = await alertsApi.getList(
                client,
                page,
                per_page,
                search,
                date_ini,
                date_fin,
                severity,
                status,
                type,
                solars,
                solar_id,
                asset_id,
                orderBy,
            )
            console.log(response.data.data)
            commit('setItems', response.data.data)
            commit('setTotalItems', response.data.meta.total[1])
        } catch (error) {
            commit(
                'notifications',
                {
                    title: i18n.t('ErrorListado', { nombre: i18n.t('Alertas') }),
                    variant: 'danger',
                },
                { root: true },
            )
            commit('setShowNotifications', true, { root: true })
        } finally {
            commit('app/loading', false, { root: true })
        }
    },
}

const getters = {
    getItems: state => state.items,
    getTotalItems: state => state.totalItems,
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
