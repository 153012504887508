<template>
  <b-card>
    <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
      <h2 class="font-weight-bolder m-0">
        <span @click="$router.go(-1)">
          <b-button
              variant="white"
              class="btn-icon rounded-circle mr-50 bg-white"
          >
            <feather-icon icon="ChevronLeftIcon" />
          </b-button>
        </span>
        {{ $t("DetallesCliente") }}
      </h2>
    </div>
    <!-- form -->
    <validation-observer ref="editClient">
      <b-form
          class="mt-2"
          @submit.prevent="handleSubmit"
          @keydown.enter="handleSubmit"
      >
        <b-row>
          <b-col sm="12">
            <b-form-group
                :label="$t('Imagen')"
                label-for="client-image"
            >
              <ImageDropzone
                  v-if="clientPrepare"
                  ref="image"
                  :files="files"
                  max-files="1"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <validation-provider
                #default="{ errors }"
                :name="$t('Nombre')"
                rules="required"
            >
              <b-form-group
                  :label="$t('Nombre')"
                  label-for="client-name"
              >
                <b-form-input
                    v-model="client.name"
                    name="name"
                    :placeholder="$t('Nombre')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col sm="6">
            <validation-provider
                #default="{ errors }"
                :name="$t('CIF')"
                rules="required"
            >
              <b-form-group
                  :label="$t('CIF')"
                  label-for="client-cif"
              >
                <b-form-input
                    v-model="client.cif"
                    name="reference"
                    :placeholder="$t('CIF')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider #default="{ errors }" :name="$t('Direccion')">
              <b-form-group :label="$t('Direccion')" label-for="client-address">
                <b-form-input
                  v-model="client.address"
                  name="reference"
                  :placeholder="$t('Direccion')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
                #default="{ errors }"
                :name="$t('CodigoPostal')"
            >
              <b-form-group
                  :label="$t('CodigoPostal')"
                  label-for="client-postalCode"
              >
                <b-form-input
                    v-model="client.postalCode"
                    name="reference"
                    :placeholder="$t('CodigoPostal')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
                #default="{ errors }"
                :name="$t('Region')"
            >
              <b-form-group
                  :label="$t('Region')"
                  label-for="client-region"
              >
                <b-form-input
                    v-model="client.region"
                    name="reference"
                    :placeholder="$t('Region')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
                #default="{ errors }"
                :name="$t('Pais')"
            >
              <b-form-group
                  :label="$t('Pais')"
                  label-for="client-country"
              >
                <b-form-input
                    v-model="client.country"
                    name="reference"
                    :placeholder="$t('Pais')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
                #default="{ errors }"
                :name="$t('Ciudad')"
            >
              <b-form-group
                  :label="$t('Ciudad')"
                  label-for="client-city"
              >
                <b-form-input
                    v-model="client.city"
                    name="reference"
                    :placeholder="$t('Ciudad')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
                #default="{ errors }"
                :name="$t('Telefono')"
            >
              <b-form-group
                  :label="$t('Telefono')"
                  label-for="client-phone"
              >
                <b-form-input
                    v-model="client.phone"
                    name="reference"
                    :placeholder="$t('Telefono')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
                #default="{ errors }"
                :name="$t('Email')"
                rules="email"
            >
              <b-form-group
                  :label="$t('Email')"
                  label-for="client-email"
              >
                <b-form-input
                    v-model="client.email"
                    name="reference"
                    :placeholder="$t('Email')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
                #default="{ errors }"
                :name="$t('Web')"
            >
              <b-form-group
                  :label="$t('Web')"
                  label-for="client-web"
              >
                <b-form-input
                    v-model="client.web"
                    name="reference"
                    :placeholder="$t('Web')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="12" v-if="role === 'super_admin'" >
            <b-form-checkbox
                id="client-assets"
                v-model="client.show_assets"
                name="check-button"
                value="1"
                switch
                inline
            >
              <span>{{ $t('assets') }}</span>
            </b-form-checkbox>
            <b-form-checkbox
                id="client-installations"
                v-model="client.show_installations"
                name="check-button"
                value="1"
                switch
                inline
            >
              <span>{{ $t('installations') }}</span>
            </b-form-checkbox>
            <b-form-checkbox
                id="client-epis"
                v-model="client.show_epis"
                name="check-button"
                value="1"
                switch
                inline
            >
              <span>{{ $t('epis') }}</span>
            </b-form-checkbox>
            <b-form-checkbox
                id="client-solars"
                v-model="client.show_solars"
                name="check-button"
                value="1"
                switch
                inline
            >
              <span>{{ $t('solars') }}</span>
            </b-form-checkbox>
          </b-col>
          <b-col cols="12 text-right">
            <b-button
                type="submit"
                variant="primary"
                class="mt-2 mr-1"
            >
              {{ $t("Enviar") }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BFormCheckbox
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import ImageDropzone from '@/components/elements/ImageDropzone/ImageDropzone.vue'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    ImageDropzone,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      files: [],
      required,
      email,
      clientPrepare: false,
    }
  },
  computed: {
    ...mapGetters({
      client: 'clients/getClient',
      role: 'auth/getRole',
    }),
  },
  methods: {
    ...mapActions({
      edit: 'clients/edit',
      getClient: 'clients/getClient',
    }),
    handleSubmit() {
      this.$refs.editClient.validate().then(success => {
        if (success) {
          const { id } = this.client
          const formData = this.createFormData()
          if (formData) {
            this.edit({ id, client: formData })
          }
        }
      })
    },
    setData() {
      if (this.client.image) {
        const file = {
          name: 'Image',
          url: this.client.image.path,
          urlDelete: `/clients/delete_image/${this.client.id}`,
        }
        this.files.push(file)
      }

      this.clientPrepare = true
    },
    createFormData() {
      const data = this.$refs.image.getFormData('image')

      console.log(this.client);

      data.append('name', this.client.name)
      if (this.role === 'super_admin') {
        data.append(
            'show_assets',
            this.client.show_assets ? 1 : 0,
        )
        data.append(
            'show_epis',
            this.client.show_epis ? 1 : 0,
        )
        data.append(
            'show_installations',
            this.client.show_installations ? 1 : 0,
        )
        data.append(
            'show_solars',
            this.client.show_solars ? 1 : 0,
        )
      }
      data.append('cif', this.client.cif ? this.client.cif : '')
      data.append('address', this.client.address ? this.client.address : '')
      data.append(
          'postalCode',
          this.client.postalCode ? this.client.postalCode : '',
      )
      data.append('region', this.client.region ? this.client.region : '')
      data.append('country', this.client.country ? this.client.country : '')
      data.append('city', this.client.city ? this.client.city : '')
      data.append('phone', this.client.phone ? this.client.phone : '')
      data.append('email', this.client.email ? this.client.email : '')
      data.append('web', this.client.web ? this.client.web : '')

      return data
    },
  },
  async created() {
    await this.getClient(this.$route.params.id)
    await this.setData()
  },
}
</script>
