<template>
  <section class="ficha" v-if="assetPrepare">
    <b-row>
      <b-col cols="12">
        <b-card no-body class="height-card position-relative px-2">
          <b-row class="my-2">
            <!-- Left: Product Image Container -->

            <b-col
              cols="12"
              lg="3"
              class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
            >
              <div
                class="d-flex align-items-center justify-content-center ficha-img"
              >
                <b-img
                    v-if="imgAsset.length > 0"
                  :alt="`Image of ${asset.name}`"
                  :src="imgAsset"
                  class="asset-img"
                  fluid
                />
                <b-img
                    v-else
                    :alt="`Image of ${asset.name}`"
                    :src="this.defaultImage"
                    class="asset-img"
                    fluid
                />
              </div>
            </b-col>

            <!-- Right: Product Details -->
            <b-col cols="12" lg="9" class="ficha-info">
              <!-- Product Name -->
              <b-card-text class="item-company mb-0">
                <p class="ficha-info_serie mb-50" v-if="asset.serial_number && asset.serial_number != -1">
                  {{ asset.serial_number }}
                </p>
                <div class="border-bottom-light">
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <h2 class="ficha-info_name mb-50">{{ asset.name }}</h2>
                    <span
                      style="white-space: nowrap"
                      class="cursor-pointer"
                      @click="visibleInfo = !visibleInfo"
                    >
                      {{ $t("MasInfo") }}
                      <feather-icon
                        :icon="
                          visibleInfo ? 'ChevronUpIcon' : 'ChevronDownIcon'
                        "
                        size="20"
                      />
                    </span>
                  </div>
                  <b-collapse id="collapse" v-model="visibleInfo" class="mt-2">
                    <div>
                      <b-row>
                        <b-col md="6" sm="12">
                          <p class="mb-50" v-if="asset.product">
                            <span class="font-weight-bolder"
                            >{{ $t("Marca") }}:</span
                            >
                            <span v-if="asset.product.marca">{{ asset.product.marca }}</span>
                            <span v-else>-</span>
                          </p>
                        </b-col>
                        <b-col md="6" sm="12">
                          <p class="mb-50" v-if="asset.product">
                            <span class="font-weight-bolder"
                              >{{ $t("Modelo") }}:</span
                            >
                            {{ asset.product.modelo }}
                          </p>
                        </b-col>
                        <b-col md="6" sm="12">
                          <p class="mb-50" v-if="asset.product.category && asset.product.category.family">
                            <span class="font-weight-bolder"
                            >{{ $t("Categoria") }}:</span
                            >
                            {{ asset.product.category.name }}
                          </p>
                        </b-col>
                        <b-col md="6" sm="12" v-if="currentRole === 'admin_empresa'">
                          <p class="mb-50" v-if="asset.client">
                            <span class="font-weight-bolder"
                              >{{ $t("Cliente") }}:</span
                            >
                            {{ asset.client.name }}
                          </p>
                        </b-col>
                       <!--<b-col cols="6" v-if="asset.pedido_num_id">
                          <p class="mb-50">
                            <span class="font-weight-bolder"
                            >{{ $t("NumeroAlbaran") }}:</span
                            >
                            {{ asset.pedido_num_id }}
                          </p>
                        </b-col>
                        <b-col cols="6" v-if="asset.coste">
                          <p class="mb-50">
                            <span class="font-weight-bolder"
                            >{{ $t("Coste") }}:</span
                            >
                            {{ priceFormatted(asset.coste) }} €
                          </p>
                        </b-col>-->
                        <b-col cols="12">
                          <div class="ficha-info_description mb-50 mt-1" v-if="asset.description != 'null'" v-html="asset.description" />
                        </b-col>
                      </b-row>
                    </div>
                  </b-collapse>
                </div>
              </b-card-text>
             <div class="mb-2 mt-1">
                <b-badge
                  v-if="asset.status"
                  :variant="statusAssets[asset.status.alias]"
                  class="mr-50"
                >
                  {{ $t(`status.assets.${asset.status.alias}`) }}
                </b-badge>
                <!--<b-badge v-if="asset.apto" variant="success">
                  {{ $t("APTO") }}
                </b-badge>
                <b-badge v-else variant="danger">
                  {{ $t("NO APTO") }}
                </b-badge>-->
              </div>
              <b-row
                class="mb-2"
                v-if="asset.status.alias == 'use' && asset.user"
              >
                <b-col cols="12" xl="4" md="6" class="mb-2">
                  <div class="bg-light-fade p-1 h-100">
                    <div class="">
                      <b-row class="">
                        <b-link
                          :to="{
                            name: 'viewUser',
                            params: { id: asset.user.id },
                          }"
                          class="mr-1"
                        >
                          <b-col cols="12" class="d-flex align-items-center">
                            <div class="pr-2">
                              <b-avatar
                                size="4rem"
                                :src="asset.user.avatar_url"
                              />
                            </div>
                            <div>
                              <h4 class="m-0 font-weight-bolder">
                                {{ asset.user.name }} {{ asset.user.surname }}
                              </h4>

                              <span
                                :key="r.display_name"
                                v-for="r in asset.user.roles"
                              >
                                {{ r.display_name }}
                              </span>
                            </div>
                          </b-col>
                        </b-link>
                      </b-row>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" xl="4" md="6" class="mb-2">
                  <div class="bg-light-fade p-1 h-100">
                    <p class="font-weight-bolder mb-0">
                      {{ $t("UltimaRevision") }}
                    </p>
                    <p class="mb-0" v-if="asset.last_review_date">{{ toDate(asset.last_review_date) }}</p>
                    <p class="mb-0" v-else>-</p>
                  </div>
                </b-col>
                <b-col cols="12" xl="4" md="6" class="mb-2">
                  <span v-if="asset.next_review_date && asset.next_review_date != 'null'">
                    <div
                        v-if="asset.next_review_date < nowTimestamp"
                        class="bg-danger-fade p-1 h-100">
                          <p class="font-weight-bolder mb-0">
                            {{ $t("ProximaRevision") }}
                          </p>
                          <p class="mb-0">{{ toDate(asset.next_review_date) }}</p>
                    </div>
                    <div
                        v-else
                        class="bg-light-fade p-1 h-100">
                          <p class="font-weight-bolder mb-0">
                            {{ $t("ProximaRevision") }}
                          </p>
                          <p class="mb-0">{{ toDate(asset.next_review_date) }}</p>
                    </div>
                  </span>
                  <span v-else>
                    <div class="bg-light-fade p-1 h-100">
                      <p class="font-weight-bolder mb-0">
                        {{ $t("ProximaRevision") }}
                      </p>
                      <p class="mb-0">-</p>
                    </div>
                  </span>
                </b-col>
                <b-col cols="12" xl="4" md="6" class="mb-2">
                  <div
                    class="bg-light-fade p-1 h-100"
                    v-if="asset.useful_review > 3"
                  >
                    <p class="font-weight-bolder mb-0">
                      {{ $t("TiempoRestante") }}
                    </p>
                    <p class="mb-0">
                      {{ asset.useful_review }} {{ $t("meses") }}
                    </p>
                  </div>
                  <div v-else-if="asset.useful_review === null">
                    <div class="bg-light-fade p-1 h-100">
                      <p class="font-weight-bolder mb-0">
                        {{ $t("TiempoRestante") }}
                      </p>
                      <p class="mb-0">
                        -
                      </p>
                    </div>
                  </div>
                  <div v-else-if="asset.useful_review === false">
                    <div class="bg-danger-fade p-1 h-100">
                      <p class="font-weight-bolder mb-0">
                        {{ $t("TiempoRestante") }}
                      </p>
                      <p v-if=" asset.useful_review" class="text-danger mb-0">
                        0 {{ $t("meses") }}
                      </p>
                      <p v-else class="mb-0">
                        -
                      </p>
                    </div>
                  </div>
                  <div v-else>
                    <div class="bg-danger-fade p-1 h-100">
                      <p class="font-weight-bolder mb-0">
                        {{ $t("TiempoRestante") }}
                      </p>
                      <p v-if=" asset.useful_review" class="text-danger mb-0">
                        {{ asset.useful_review }} {{ $t("meses") }}
                      </p>
                      <p v-else class="mb-0">
                        -
                      </p>
                    </div>
                  </div>

                </b-col>
                <b-col cols="12" xl="4" md="6" class="mb-2 d-lg-block d-none">
                  <div class="bg-light-fade p-1 h-100">
                    <p class="font-weight-bolder mb-0">
                      {{ $t("FechaCompra") }}
                    </p>
                    <p class="mb-0" v-if="asset.purchase_date">{{ toDate(asset.purchase_date) }}</p>
                    <p class="mb-0" v-else>-</p>
                  </div>
                </b-col>
                <b-col cols="12" xl="4" md="6" class="mb-2 d-lg-block d-none">
                  <div class="bg-light-fade p-1 h-100">
                    <p class="font-weight-bolder mb-0">
                      {{ $t("FechaCaducidad") }}
                    </p>
                    <p class="mb-0" v-if="asset.date_of_expiry">{{ toDate(asset.date_of_expiry) }}</p>
                    <p class="mb-0" v-else>-</p>
                  </div>
                </b-col>
                <b-col cols="12" xl="4" md="6" class="mb-2 d-lg-block d-none">
                  <div
                    class="bg-light-fade p-1 h-100"
                    v-if="asset.useful_life > 12"
                  >
                    <p class="font-weight-bolder mb-0">{{ $t("VidaUtil") }}</p>
                    <p class="mb-0">
                      {{ asset.useful_life }} {{ $t("meses") }}
                    </p>
                  </div>
                  <div v-else-if="asset.useful_life === null">
                    <div class="bg-light-fade p-1 h-100">
                      <p class="font-weight-bolder mb-0">{{ $t("VidaUtil") }}</p>
                      <p class=" mb-0">
                        -
                      </p>
                    </div>
                  </div>
                  <div v-else-if="asset.useful_life === 0">
                    <div class="bg-light-fade p-1 h-100">
                      <p class="font-weight-bolder mb-0">{{ $t("VidaUtil") }}</p>
                      <p class=" mb-0">
                        {{ asset.useful_life }} {{ $t("meses") }}
                      </p>
                    </div>
                  </div>
                  <div v-else>
                    <div class="bg-danger-fade p-1 h-100">
                      <p class="font-weight-bolder mb-0">{{ $t("VidaUtil") }}</p>
                      <p class="text-danger mb-0">
                        {{ $t("Caducado") }}
                      </p>
                    </div>
                  </div>

                </b-col>
                <b-col cols="12">
                  <div class="mb-1" v-if="asset.product.docs.manualUso || asset.product.docs.fichaTecnica || asset.product.docs.certificadoConformidad">
                    <p class="font-weight-bolder mb-50">
                      {{ $t("DocumentosFabricante") }}
                    </p>
                    <!--  <b-link
                        v-if="asset.product.docs.manualUso"
                        :href="asset.product.docs.manualUso"
                        target="_blank"
                        class="mr-1 mb-1 link"
                    >
                      {{ $t('manualUso') }}
                    </b-link> -->
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      v-if="asset.product.docs.manualUso"
                      @click="showModalDocuments(asset.product.docs.manualUso)"
                      style="margin-right: 10px"
                    >
                      <feather-icon icon="FileIcon" class="mr-50" size="16" />
                      <span class="align-middle"> {{ $t("manualUso") }}</span>
                    </b-button>
                    <p class="font-weight-bolder mb-50 d-block d-lg-none"></p>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      v-if="asset.product.docs.fichaTecnica"
                      @click="showModalDocuments(asset.product.docs.fichaTecnica)"
                      style="margin-right: 10px"
                    >
                      <feather-icon icon="FileIcon" class="mr-50" size="16" />
                      <span class="align-middle">
                        {{ $t("fichaTecnica") }}</span
                      >
                    </b-button>
                    <!--     <b-link
                        v-if="asset.product.docs.fichaTecnica"
                        :href="asset.product.docs.fichaTecnica"
                        target="_blank"
                        class="mr-1 mb-1 link"
                    >
                      {{ $t('fichaTecnica') }}
                    </b-link> -->
                    <p class="font-weight-bolder mb-50 d-block d-lg-none"></p>
                    <!--   <b-link
                        v-if="asset.product.docs.certificadoConformidad"
                        :href="asset.product.docs.certificadoConformidad"
                        target="_blank"
                        class="mr-1 mb-1 link"
                    >
                      {{ $t('certificadoConformidad') }}
                    </b-link> -->
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      v-if="asset.product.docs.certificadoConformidad"
                      @click="showModalDocuments(asset.product.docs.certificadoConformidad)"
                    >
                      <feather-icon icon="FileIcon" class="mr-50" size="16" />
                      <span class="align-middle">
                        {{ $t("certificadoConformidad") }}</span
                      >
                    </b-button>
                  </div>
                </b-col>
                <b-col cols="12">
                  <div class="mb-1" v-if="asset.archives.length > 0">
                    <p class="font-weight-bolder mb-50">
                      {{ $t("DocumentosTrazabilidad") }}
                    </p>
                    <!--   <b-link
                      v-if="asset.archives && asset.archives.review"
                      :href="asset.archives.review.url"
                      target="_blank"
                      class="mr-1 mb-1 link"
                    >
                      {{ $t('Certificado revisión') }}
                    </b-link> -->
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      v-if="asset.archives && asset.archives.review"
                      @click="showModalDocuments(asset.archives.review.url)"
                      style="margin-right: 10px"
                    >
                      <feather-icon icon="FileIcon" class="mr-50" size="16" />
                      <span class="align-middle">
                        {{ $t("Certificado revisión") }}</span
                      >
                    </b-button>
                    <span v-else>-</span>
                    <p class="font-weight-bolder mb-50 d-block d-lg-none"></p>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      v-if="canDownload && fileDownload"
                      :href="fileDownload"
                      target="_blank"
                      class="word-break-all"
                    >
                      <feather-icon icon="FileIcon" class="mr-50" size="16" />
                      <span class="align-middle"> {{ textDownload }}</span>
                    </b-button>
                    <!-- <b-link
                      v-if="canDownload && fileDownload"
                      :href="fileDownload"
                      target="_blank"
                      class="mr-1 mb-1 link"
                    >
                      {{ textDownload }}
                    </b-link> -->
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="12" lg="4" class="ficha-tag d-lg-block d-none" v-if="asset.tag && asset.tag.length > 0">
        <b-card no-body class="height-card">
          <div class="p-2" >
            <h5 class="mb-1 font-weight-bolder">{{ $t("QR") }}</h5>
            <div class="text-center mb-2">
              <img :src="asset.tag.image_url" class="img-fluid" />
            </div>
            <p class="mb-50" v-if="asset.tag.last_scan">
              <span class="font-weight-bolder"
                >{{ $t("UltimoRegistro") }}:</span
              >
              {{ toDateWithTime(asset.tag.last_scan) }}
            </p>
            <p class="mb-50">
              <span class="font-weight-bolder"
                >{{ $t("TotalRegistros") }}:</span
              >
              {{ asset.tag.total_scans }}
            </p>
          </div>
        </b-card>
      </b-col>
      <!--   <b-col cols="12" lg="8" class="ficha-localizacion d-lg-block  d-none"> -->
      <b-col cols="12" lg="8" class="ficha-localizacion d-lg-block" v-if="(latitude && longitude) || locActual.length > 0">
        <b-card no-body class="height-card">
          <div class="py-1 px-2">
            <h5 class="mb-1 font-weight-bolder">{{ $t("Localizacion") }}</h5>
            <p class="mb-1" v-if="locActual">{{ asset.location }}</p>
            <!--   <div class="mb-1" v-else>
              <p class="mb-50">{{ $t("Evento") }}: {{ textEventLoc }}</p>
              <span class="link cursor-pointer" @click="setData()">{{
                $t("VerLocalizacionActual")
              }}</span>
            </div> -->
            <div v-if="latitude && longitude">
              <l-map :zoom="zoom" :center="center">
                <l-tile-layer :url="url" />
                <l-marker :lat-lng="[latitude, longitude]" />
              </l-map>
            </div>
          </div>
        </b-card>
      </b-col>
      <!--  <b-col
        cols="12"
        class="ficha-mantenimiento d-lg-block d-none"
        v-if="items.length"
      > -->
      <b-col cols="12" v-if="asset.solars && asset.solars.length > 0">
        <b-card>
          <h4 class="font-weight-bolder">{{ $t("solar") }}</h4>
          <hr class="my-1" />
          <b-card bg-variant="light" no-body>
            <ListSolars
                :solarprop="asset.solars[0].id"
                :showStadistics="false"
                :hidden="true"
                :showHeader="false"
                :showActions="false"
            ></ListSolars>
          </b-card>

        </b-card>
      </b-col>
      <b-col
          cols="12"
          class="ficha-mantenimiento d-lg-block"
      >
        <b-card no-body>
          <b-row>
            <b-col sm="12" md="10">
              <b-tabs
                  style="font-weight: bolder"
                  lazy pills
                  nav-class="flex-column flex-md-row"
                  :align="(['xs', 'sm'].includes(breakpoint)) ? 'center' : 'start'"
                  @activate-tab="tab = $event"
              >
                <b-tab :active="tab === 0">
                  <template #title>
                    {{ $t("Actions") }}
                  </template>
                </b-tab>
                <b-tab :active="tab === 1">
                  <template #title>
                    {{ $t("NextActions") }}
                  </template>
                </b-tab>
                <b-tab :active="tab === 2">
                  <template #title>
                    {{ $t("ExternalActions") }}
                  </template>
                </b-tab>
                <b-tab :active="tab === 3">
                  <template #title>
                    {{ $t("Alertas") }}
                  </template>
                </b-tab>
              </b-tabs>
            </b-col>
            <b-col
              sm="12"
              md="2"
              class="d-flex justify-content-end align-self-center pr-2"
              :class="{ 'py-1': ['xs', 'sm'].includes(breakpoint) }"
            >
              <div v-if="tab === 2 && (currentRole === 'admin_empresa' || currentRole === 'admin_cliente' || currentRole === 'super_admin')">
                <b-button
                  @click="externalActionModal = true"
                  variant="outline-primary"
                >
                  <span> {{ $t("AddAction") }}</span>
                </b-button>
              </div>
              <div v-else class="d-flex" >
                <span v-if="['xs', 'sm'].includes(breakpoint)" style="margin-right: 5px">
                  {{ $t("Filters") }}
                </span>
                <feather-icon
                  class="mr-1 cursor-pointer d-flex align-self-center"
                  icon="FilterIcon"
                  size="20"
                  @click="visibleFilter = !visibleFilter"
                />
              </div>
            </b-col>
          </b-row>
          <template v-if="tab === 0">
            <ActionsTable :visibleFilter="visibleFilter" type="asset" />
          </template>
          <template v-if="tab === 1">
            <NextActionsTable :visibleFilter="visibleFilter" type="asset" />
          </template>
          <template v-if="tab === 2">
            <ExternalActionsTable type="asset" />
          </template>
          <template v-if="tab === 3">
            <ListTable :visibleFilter="visibleFilter" :showAsset="asset.id"/>
          </template>
        </b-card>
      </b-col>
    </b-row>
    <CreateExternalActionModal v-model="externalActionModal" object_type="asset" />
    <b-modal
      hide-footer
      centered
      size="lg"
      v-model="modalShow"
      body-class="p-0"
    >
      <!-- Contenido del modal -->
      <iframe
        :key="iframeKey"
        :src="iframeSrc"
        width="100%"
        height="400px"
        sandbox="allow-scripts allow-popups allow-forms allow-top-navigation"
      ></iframe>
    </b-modal>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  BCard,
  BRow,
  BCol,
  BImg,
  BCardText,
  BLink,
  BButton,
  BBadge,
  BTable,
  BCollapse,
  BPagination,
  BAvatar,
  BFormGroup,
  BTab,
  BTabs,
  BModal
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { isUserLoggedIn } from "@/auth/utils";
import { config } from "@/shared/app.config";
import { LMap, LMarker, LTileLayer } from "vue2-leaflet";
import { latLng, Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import {
  TimestampToFlatPickr,
  TimestampToFlatPickrWithTime,
} from "@/libs/helpers";
import SelectUsers from "@/components/selectUsers/SelectUsers";
import ActionsTable from "@/components/actions/table/ActionsTable.vue";
import NextActionsTable from "@/components/actions/table/NextActionsTable.vue";
import CreateExternalActionModal from "@/components/actions/modal/CreateExternalActionModal.vue"
import ExternalActionsTable from "@/components/actions/table/ExternalActionsTable.vue";
import AssetsCardTable from "@/components/assets/card/AssetsCardTable.vue";
import ListSolars from '@/views/solar_installation/ListTable'
import ListTable from "@/views/alerts/ListTable.vue";


delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  components: {
    ListTable,
    AssetsCardTable,
    CreateExternalActionModal, ExternalActionsTable, NextActionsTable, BTab, ActionsTable, BTabs,
    SelectUsers,
    BRow,
    BCol,
    BCard,
    BImg,
    BCardText,
    BBadge,
    BTable,
    BLink,
    BButton,
    LMap,
    LTileLayer,
    LMarker,
    BCollapse,
    BPagination,
    BAvatar,
    BFormGroup,
    BModal,
    ListSolars
  },
  directives: {
    Ripple,
  },
  mounted() {
    this.stacked = window.innerWidth <= 1400;
    window.addEventListener("resize", () => {
      this.stacked = window.innerWidth <= 1400;
    });
  },
  data() {
    return {
      tab: 0,
      currentPage: 1,
      modalShow: false,
      iframeSrc: '', // URL dinámica para el iframe
      iframeKey: 0,
      pages: ["10", "15", "25"],
      pageLength: 10,
      user: null,
      visibleInfo: false,
      statusAssets: config.statusAssetsVariants,
      stacked: false,
      visibleFilter: false,
      nowTimestamp: Math.floor(Date.now() / 1000),
      fieldsMantenimiento: [
        { key: "created_at", label: this.$t("Fecha") },
        { key: "created_at_hour", label: this.$t("Hora") },
        { key: "type", label: this.$t("Evento") },
        { key: "assign_user", label: this.$t("Usuario") },
        { key: "user", label: this.$t("Responsable") },
        /*   { key: 'user_roles', label: this.$t('Rol') }, */
        { key: "status", label: this.$t("Estado") },
        { key: "location", label: this.$t("Localizacion") },
        { key: "actions", label: this.$t("Acciones"), class: "text-right" },
      ],
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      urlLocation: "",
      zoom: 13,
      zoomLocation: 5,
      latitude: 0,
      longitude: 0,
      locActual: true,
      textEventLoc: "",
      assetPrepare: false,
      externalActionModal: false,
      defaultImage: require("@/assets/images/default/asset.png")
    };
  },
  computed: {
    ...mapGetters({
      asset: "assets/getAsset",
      items: "events/getItemsLogs",
      totalItems: "events/getTotalItemsLogs",
      currentRole: 'auth/getRole',
      breakpoint: "app/currentBreakPoint",
    }),
    canDownload() {
      if (this.asset && this.asset.status) {
        if (
          this.asset.status.alias === "incidence" ||
          this.asset.status.alias === "use"
        ) {
          return true;
        }
      }
      return false;
    },
    fileDownload() {
      if (this.asset && this.asset.status) {
        if (
          this.asset.status.alias === "incidence" &&
          this.asset.archives.incidence
        ) {
          return this.asset.archives.incidence.url;
        } else if (
          this.asset.status.alias === "use" &&
          this.asset.archives.delivery
        ) {
          return this.asset.archives.delivery.url;
        }
      }
      return false;
    },
    textDownload() {
      if (this.asset && this.asset.status) {
        if (
          this.asset.status.alias === "incidence" &&
          this.asset.archives.incidence
        ) {
          return this.$t("InformeIncidencia");
        } else if (
          this.asset.status.alias === "use" &&
          this.asset.archives.delivery
        ) {
          return this.$t("CertificadoEntrega");
        }
      }
      return "";
    },
    center() {
      return latLng(this.latitude, this.longitude);
    },
    imgAsset() {
      if (this.asset.product && this.asset.product.imagen) {
        return this.asset.product.imagen
      }
      return ''
    },
    isLogin() {
      return isUserLoggedIn();
    },
  },
  watch: {
    currentPage() {
      this.chargeData();
    },
    pageLength() {
      this.chargeData();
    },
    user() {
      this.currentPage = 1;
      this.chargeData();
    },
  },
  methods: {
    ...mapActions({
      getAsset: "assets/getAsset",
      getListLogs: "events/getListLogs",
    }),
    showModalDocuments(doc) {
      this.iframeSrc = doc
      this.iframeKey += 1
      this.modalShow = true
    },
    toDate(f) {
      if (f) {
        return TimestampToFlatPickr(f);
      }
      return "";
    },
    toDateWithTime(f) {
      if (f) {
        return TimestampToFlatPickrWithTime(f);
      }
      return "";
    },
    chargeData() {
      this.getListLogs({
        id: this.$route.params.id,
        page: this.currentPage,
        per_page: this.pageLength,
        search: this.searchTerm,
        user: this.user?.id,
        type_object: 'asset',
      });
    },
    handleSearch() {
      this.currentPage = 1;
      this.chargeData();
    },
    openMap(latitude, longitude, text, hour) {
      this.latitude = latitude;
      this.longitude = longitude;
      this.locActual = false;
      this.textEventLoc = `${text} - ${hour}`;
    },
    setData() {
      if (this.asset.location_relation) {
        this.latitude = this.asset.location_relation.latitude;
        this.longitude = this.asset.location_relation.longitude;
      } else {
        this.latitude = "";
        this.longitude = "";
      }
      this.locActual = true;
      this.assetPrepare = true;
    },
    handleOpenMapLinkClick(asset) {
      this.openMap(
        asset.location?.latitude || asset.latitude,
        asset.location?.longitude || asset.longitude,
        this.$t(`typeevents.${asset.type}`),
        this.toDateWithTime(asset.created_at),
      )
    },
    priceFormatted(price) {
      let val = (price / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
  },
  async created() {
    await this.getAsset(this.$route.params.id);
    await this.setData();
    await this.chargeData();
  },
};
</script>
<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.vue2leaflet-map {
  &.leaflet-container {
    height: 350px;
  }
}
.dropdown-user{
  list-style-type: none;
}
[dir] .b-nav-dropdown .dropdown-toggle::after{
  display: none;
}
</style>
