import i18n from '@/libs/i18n'
import productsApi from '../../api/products-api'
import router from '../../router'

const state = {
    product: {},
    selectProducts: [],
    items: [],
    totalItems: 0,
    itemsDashboard: [],
    totalItemsDashboard: 0,
}

const mutations = {
    setProduct(state, val) {
        state.product = val
    },
    setItems(state, val) {
        state.items = val
    },
    setTotalItems(state, val) {
        state.totalItems = val
    },
    setItemsDashboard(state, val) {
        state.itemsDashboard = val
    },
    setTotalItemsDashboard(state, val) {
        state.totalItemsDashboard = val
    },
    setSelectProducts(state, val) {
        state.selectProducts = val
    },
}

const actions = {
    async getProduct({ commit }, id) {
        commit('app/loading', true, { root: true })
        await productsApi.getProduct(id)
            .then(
                response => {
                    commit('setProduct', response.data.data)
                    commit('app/loading', false, { root: true })
                },
                () => {
                    commit('app/loading', false, { root: true })
                    commit('notifications', { title: i18n.t('ErrorGet', { nombre: i18n.t('Producto') }), variant: 'danger' }, { root: true })
                    commit('setShowNotifications', true, { root: true })
                },
            )
    },
    getListProducts({ commit }, {
        company = '', page, per_page, search = '', group = '', client = '', type = ''
    }) {
        commit('app/loading', true, { root: true })
        productsApi.getList(company, page, per_page, search, group, client, type)
            .then(
                response => {
                    commit('setItems', response.data.data)
                    commit('setTotalItems', response.data.meta.total[1])
                    commit('app/loading', false, { root: true })
                },
                () => {
                    commit('app/loading', false, { root: true })
                    commit('notifications', { title: i18n.t('ErrorListado', { nombre: i18n.t('Productos') }), variant: 'danger' }, { root: true })
                    commit('setShowNotifications', true, { root: true })
                },
            )
    },
    getListDashboard({ commit }, {
        company = '', page, per_page, search = '',
    }) {
        commit('app/loading', true, { root: true })
        productsApi.getListDashboard(company, page, per_page, search)
            .then(
                response => {
                    commit('setItemsDashboard', response.data.data)
                    commit('setTotalItemsDashboard', response.data.meta.total[1])
                    commit('app/loading', false, { root: true })
                },
                () => {
                    commit('app/loading', false, { root: true })
                    commit('notifications', { title: i18n.t('ErrorListado', { nombre: i18n.t('Productos') }), variant: 'danger' }, { root: true })
                    commit('setShowNotifications', true, { root: true })
                },
            )
    },
    async getSelectProducts({ commit }, {
        company = '', search = '', page = 1, per_page = 9999999,
    }) {
        await productsApi.getList(company, page, per_page, search)
            .then(
                response => {
                    commit('setTotalItems', response.data.meta.total[1])
                    commit('setSelectProducts', response.data.data)
                },
                () => {
                    commit('notifications', { title: i18n.t('ErrorListado', { nombre: i18n.t('Productos') }), variant: 'danger' }, { root: true })
                    commit('setShowNotifications', true, { root: true })
                },
            )
    },
    edit({ commit }, { id, product }) {
        commit('app/loading', true, { root: true })
        productsApi.edit(id, product).then(
            () => {
                commit(
                    'notifications',
                    { title: i18n.t('SuccessUpdateMasculino', { nombre: i18n.t('Producto') }), variant: 'success' },
                    { root: true },
                )
                commit('setShowNotifications', true, { root: true })
                commit('app/loading', false, { root: true })
            },
            () => {
                commit('app/loading', false, { root: true })
                commit(
                    'notifications',
                    { title: i18n.t('ErrorUpdate', { nombre: i18n.t('Producto') }), variant: 'danger' },
                    { root: true },
                )
                commit('setShowNotifications', true, { root: true })
            },
        )
    },
    create({ commit }, { product }) {
        commit('app/loading', true, { root: true })
        productsApi.create(product).then(
            response => {
                commit(
                    'notifications',
                    { title: i18n.t('SuccessCreateMasculino', { nombre: i18n.t('Equipo') }), variant: 'success' },
                    { root: true },
                )
                commit('setShowNotifications', true, { root: true })
                router.push({ name: 'products' })
                commit('app/loading', false, { root: true })
            },
            () => {
                commit('app/loading', false, { root: true })
                commit(
                    'notifications',
                    { title: i18n.t('ErrorCreate', { nombre: i18n.t('Producto') }), variant: 'danger' },
                    { root: true },
                )
                commit('setShowNotifications', true, { root: true })
            },
        )
    },

}

const getters = {
    getProduct: state => state.product,
    getItems: state => state.items,
    getTotalItems: state => state.totalItems,
    getItemsDashboard: state => state.itemsDashboard,
    getTotalItemsDashboard: state => state.totalItemsDashboard,
    getSelectProducts: state => state.selectProducts,
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
