<template>
  <li
    v-if="canViewVerticalNavMenuLink(item)"
    class="nav-item"
    :class="{
      'active': isActive,
      'disabled': item.disabled
    }"
  >
    <b-link
      v-bind="linkProps"
      class="d-flex align-items-center"
      @click="handleClick"
    >
      <feather-icon :icon="item.icon || 'CircleIcon'" />
      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
    <!-- Modal -->
    <b-modal v-if="linkProps.openModal" v-model="isModalVisible" size="xl" hide-footer hide-header content-class="bg-transparent" body-class="p-0">
      <iframe style="border: none" :src="linkProps.iframeUrl" width="100%" height="800px"></iframe>
    </b-modal>
  </li>
</template>

<script>
import { useUtils as useAclUtils } from '@core/libs/acl'
import { BLink, BBadge, BModal } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BLink,
    BBadge,
    BModal
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item)
    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()
    const isModalVisible = ref(false)

    const handleClick = (event) => {
      if (linkProps.value.openModal) {
        event.preventDefault()
        isModalVisible.value = true
      }
    }

    return {
      isActive,
      linkProps,
      updateIsActive,
      isModalVisible,
      handleClick,
      // ACL
      canViewVerticalNavMenuLink,
      // i18n
      t,
    }
  },

}
</script>
