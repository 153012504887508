<template>
  <div class="pb-1">
    <div>
      <b-collapse id="collapse" v-model="visibleFilter" class="mt-2">
        <div class="px-2">
          <b-row>
            <b-col sm="4">
              <b-form-group :label="$t('Usuario')" label-for="filter-order">
                <SelectUsers v-model="user" />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-collapse>
    </div>
    <b-table striped responsive :fields="fieldsMantenimiento" :items="items">
      <!-- :stacked="stacked" -->
      <template #cell(created_at)="data">
        {{ toDateWithTime(data.item.created_at).toString().split(" ")[0] }}
      </template>
      <template #cell(created_at_hour)="data">
        {{ toDateWithTime(data.item.created_at).toString().split(" ")[1] }}
      </template>
      <template #cell(type)="data">
        {{ $t(`typeevents.${data.item.type}`) }}
      </template>
      <template #cell(assign_user)="{ value }">
        <b-link
            :to="{
            name: 'viewUser',
            params: { id: value.id },
          }"
            target="_blank"
            class="link"
        >
          <span class="text-nowrap">
            {{ value.name }}
            {{ value.surname }}
          </span>
        </b-link>
      </template>
      <template #cell(user)="{ value }">
        <b-link
            :to="{
            name: 'viewUser',
            params: { id: value.id },
          }"
            target="_blank"
            class="link"
        >
          <span class="text-nowrap">
            {{ value.name }}
            {{ value.surname }}
          </span>
        </b-link>
      </template>
      <template #cell(status)="data">
        <div class="mb-2 mt-1">
          <b-badge
              v-if="data.item.status"
              :variant="returnStatus(data.item.status.alias, data.item.object_type)"
              class="mr-50"
          >
            {{ $t(`status.${data.item.object_type}s.${data.item.status.alias}`) }}
          </b-badge>
          <b-badge v-if="!data.item.apto && (data.item.object_type == 'asset' || data.item.object_type == 'epi')" variant="danger">
            {{ $t("NO APTO") }}
          </b-badge>
        </div>
      </template>
      <template #cell(actions)="data">
        <b-link v-if="data.item.url" :href="data.item.url" target="_blank">
          <feather-icon icon="DownloadIcon" class="ml-50" />
        </b-link>
        <b-link
            v-if="data.item.certificate_id"
            :href="data.item.certificate_id"
            target="_blank"
            :title="$t('Documento TSA')"
        >
          <feather-icon icon="PaperclipIcon" class="ml-50" />
        </b-link>
      </template>
    </b-table>
    <div v-if="totalItems > 10" class="d-flex justify-content-center">
      <b-pagination
          v-model="currentPage"
          :total-rows="totalItems"
          :per-page="pageLength"
          first-number
          last-number
          align="right"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { config } from "@/shared/app.config";
import {
  returnStatus,
  TimestampToFlatPickr,
  TimestampToFlatPickrWithTime,
} from "@/libs/helpers";
import SelectUsers from "@/components/selectUsers/SelectUsers";

export default {
  props: {
    visibleFilter: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: '',
    }
  },
  components: {
    SelectUsers,
  },
  data() {
    return {
      currentPage: 1,
      pages: ["10", "15", "25"],
      pageLength: 10,
      user: null,
      statusAssets: config.statusAssetsVariants,
      fieldsMantenimiento: [
        { key: "created_at", label: this.$t("Fecha") },
        { key: "created_at_hour", label: this.$t("Hora") },
        { key: "type", label: this.$t("Evento") },
        { key: "assign_user", label: this.$t("Usuario") },
        { key: "user", label: this.$t("Responsable") },
        { key: "status", label: this.$t("Estado") },
        { key: "actions", label: this.$t("documentacion"), class: "text-right" },
      ],
    };
  },
  watch: {
    currentPage() {
      this.loadData();
    },
    pageLength() {
      this.loadData();
    },
    user() {
      this.currentPage = 1;
      this.loadData();
    },
  },
  computed: {
    ...mapGetters({
      items: "events/getItemsLogs",
      totalItems: "events/getTotalItemsLogs",
    }),
  },
  methods: {
    ...mapActions({
      getListLogs: "events/getListLogs",
    }),
    toDate(f) {
      if (f) {
        return TimestampToFlatPickr(f);
      }
      return "";
    },
    toDateWithTime(f) {
      if (f) {
        return TimestampToFlatPickrWithTime(f);
      }
      return "";
    },
    loadData() {
      this.getListLogs({
        id: this.$route.params.id,
        page: this.currentPage,
        per_page: this.pageLength,
        search: this.searchTerm,
        user: this.user?.id,
        type_object: this.type,
      });
    },
    returnStatus(alias, type) {
      return returnStatus(alias, type)
    },
  },
  async mounted() {
    await this.loadData();
  },
};
</script>
