<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <div
              class="d-flex justify-content-between align-items-center px-1 py-1"
          >
            <div>
              <h3 class="m-0 py-1">
                {{ $t("Etiquetas") }}
              </h3>
            </div>
            <div class="d-flex align-items-center">
              <div class="d-flex align-items-right">
                <b-link :to="{ name: 'createTag' }">
                  <b-button
                      variant="primary"
                      class="mr-1 ml-50 text-nowrap"
                  >
                    {{ $t("AñadirEtiquetas") }}
                  </b-button>
                </b-link>
              </div>
              <b-nav-item-dropdown
                  right
                  toggle-class="d-flex align-items-center dropdown-user-link"
                  class="dropdown-user"
              >
                <template #button-content>
                  <feather-icon
                      size="16"
                      icon="MoreHorizontalIcon"
                  />
                </template>

                <b-dropdown-item
                    @click="download()"
                    link-class="d-flex align-items-center"
                >
                  <feather-icon
                      size="16"
                      icon="DownloadIcon"
                      class="mr-50"
                  />
                  <span> {{ $t('Descargar lista Excel') }}</span>
                </b-dropdown-item>
                <!--<b-dropdown-item
                  link-class="d-flex align-items-center"
                  >
                  <b-link href="https://tusetiquetasqr.com/" target="_blank" >
                  <feather-icon
                      size="16"
                      icon="ShoppingCartIcon"
                      class="mr-50"
                  />
                  <span> {{ $t("PedirEtiquetas") }}</span>
                  </b-link>
                </b-dropdown-item>-->
                <b-dropdown-divider />
                <b-dropdown-item
                    link-class="d-flex align-items-center"
                    @click="seeHelp()"
                >
                  <feather-icon
                      icon="HelpCircleIcon"
                      size="16"
                      class="mr-50"
                  />
                  <span> {{ $t("Ayuda") }}</span>
                </b-dropdown-item>
              </b-nav-item-dropdown>
              <span
                  class="ml-1 cursor-pointer"
                  @click="visibleFilter = !visibleFilter"
              >
                <feather-icon
                    icon="FilterIcon"
                    size="20"
                />
              </span>
            </div>
          </div>
          <div>
            <b-collapse
                id="collapse"
                v-model="visibleFilter"
                class="mt-2"
            >
              <div class="px-2">
                <b-row>
                  <b-col sm="4">
                    <b-form-group
                        :label="$t('Busqueda')"
                        label-for="filter-search"
                    >
                      <b-row>
                        <b-col sm="8">
                          <b-form-input
                              v-model="searchTerm"
                              :placeholder="$t('Busqueda')"
                              type="text"
                              class="d-inline-block"
                          />
                        </b-col>
                        <b-col
                            sm="4"
                            style="padding-left: 0px"
                        >
                          <b-button
                              variant="primary"
                              @click="handleSearch"
                          >
                            {{ $t("Buscar") }}
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-col>
                  <b-col sm="8">
                    <b-form-group
                        :label="$t('Producto')"
                        label-for="asset-product"
                    >
                      <SelectProducts v-model="product" />
                    </b-form-group>
                  </b-col>
                  <b-col
                      v-if="role === 'admin_empresa' && !currentClient"
                      sm="4"
                  >
                    <b-form-group
                        :label="$t('Cliente')"
                        label-for="filter-client"
                    >
                      <v-select
                          v-model="client"
                          label="name"
                          :filterable="true"
                          :searchable="true"
                          :options="listClients"
                          :placeholder="$t('Cliente')"
                      >
                        <template
                            slot="option"
                            slot-scope="option"
                        >
                          {{ option.name }}
                        </template>
                        <template
                            slot="selected-option"
                            slot-scope="option"
                        >
                          {{ option.name }}
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group :label="$t('Estado')" label-for="filter-status">
                      <v-select
                          v-model="status"
                          label="alias"
                          :filterable="false"
                          :searchable="false"
                          :options="selectStatus"
                          :placeholder="$t('Estado')"
                      >
                        <template slot="option" slot-scope="option">
                          {{ $t(option.name) }}
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          {{ $t(option.name) }}
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>

                  <b-col
                      sm="4"
                  >
                    <b-form-group
                        :label="$t('Serie')"
                        label-for="filter-serie"
                    >
                      <v-select
                          v-model="serie"
                          label="name"
                          :filterable="true"
                          :searchable="true"
                          :options="listSeries"
                          :placeholder="$t('Serie')"
                      >
                        <template
                            slot="option"
                            slot-scope="option"
                        >
                          {{ option.name }}
                        </template>
                        <template
                            slot="selected-option"
                            slot-scope="option"
                        >
                          {{ option.name }}
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group label-for="filter-client" :label="$t('Fecha')">
                      <flat-pickr v-model="date" name="date" class="form-control" :placeholder="$t('Fecha')"
                                  :config="dateConfig" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </b-collapse>
          </div>
          <b-table
              v-if="items"
              hover
              responsive
              :items="items"
              :fields="seleccionarcolumnas"
          >
            <template #cell(client)="data">
              <span v-if="data.item.client">
                {{ data.item.client.name }}
              </span>
            </template>
            <template #cell(created_at)="data">
              {{
                toDate(data.item.created_at).toString().split(" ")[0]
              }}
            </template>
            <template #cell(status)="data">
              <b-badge :variant="statusTags[data.item.status_tag.alias]">
                {{ $t(`status.tags.${data.item.status_tag.alias}`) }}
              </b-badge>
            </template>
            <template #cell(object)="data">
              <span v-if="data.item.object && data.item.object.data && data.item.type_tag.alias === 'asset'">
                 <b-link
                     :to="{ name: 'viewAsset', params: { id: data.item.object.data.id } }"
                     class="mr-1"
                 >
                   <span>{{ data.item.object.data.name }}</span>
                 </b-link>
              </span>
              <span v-if="data.item.object && data.item.object.data && data.item.type_tag.alias === 'epi'">
                 <b-link
                     :to="{ name: 'viewEpi', params: { id: data.item.object.data.id } }"
                     class="mr-1"
                 >
                   <span>{{ data.item.object.data.name }}</span>
                 </b-link>
              </span>
              <span v-if="data.item.object && data.item.object.data && data.item.type_tag.alias === 'solar'">
                 <b-link
                     :to="{ name: 'viewSolar', params: { id: data.item.object.data.id } }"
                     class="mr-1"
                 >
                   <span>{{ data.item.object.data.name }}</span>
                 </b-link>
              </span>
              <span v-if="data.item.object && data.item.object.data && data.item.type_tag.alias === 'installation'">
                 <b-link
                     :to="{ name: 'viewInstallation', params: { id: data.item.object.data.id } }"
                     class="mr-1"
                 >
                   <span>{{ data.item.object.data.name }}</span>
                 </b-link>
              </span>
              <span v-if="data.item.object && data.item.object.data && data.item.type_tag.alias === 'form'">
                 <b-link
                     :to="{ name: 'forms' }"
                     class="mr-1"
                 >
                   <span>{{ data.item.object.data.name }}</span>
                 </b-link>
              </span>
            </template>
            <template #cell(actions)="data">
              <span
                  class="cursor-pointer"
                  @click="seeTag(data.item)"
              >
                  <feather-icon icon="EyeIcon" />
                </span>
              <span
                  v-if="data.item.status_tag.alias === 'free'"
                  class="text-danger cursor-pointer ml-1"
                  @click="deleteTag(data.item.id, data.item.code)"
              >
                  <feather-icon icon="TrashIcon" />
                </span>
            </template>
          </b-table>
        </b-card>
        <div class="d-flex align-items-center justify-content-between mb-2">
          <div class="d-flex align-items-center mb-0">
            <span class="text-nowrap"> {{ $t("PorPagina") }}: </span>
            <b-form-select
                v-model="pageLength"
                :options="pages"
                class="ml-50 mr-1"
                @input="handlePageChange"
            />
            <span class="text-nowrap">
              {{ $t("Total") }}: {{ totalItems }}</span>
          </div>
          <div>
            <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-modal
        id="modalSeeTag"
        hide-footer
        :hide-header="true"
        centered
        size="md"
        v-model="modalSeeShow"
    >
      <div v-if="imageCurrentTag" >
        <img :src="imageCurrentTag" class="img-fluid w-100" />
        <div class="text-center my-2" >
          <b-button
              variant="primary"
              @click.prevent="downloadItem(imageCurrentTag, 'tag-' + currentTagID)"
          >
            {{ $t("DESCARGAR") }}
          </b-button>
        </div>
      </div>
    </b-modal>
    <b-modal
        id="modalHelpShop"
        hide-footer
        centered
        size="md"
        v-model="modalHelpShop"
        :title="$t('TitleAyuda')"
    >
      <div>
        <p>{{ $t('HelpModal1') }}</p>
        <p>{{ $t('HelpModal2') }}</p>
        <ul style="list-style-type: none">
          <li>10-Trace-1</li>
          <li>10-Trace-2</li>
          <li>10-Trace-3</li>
          <li>10-Trace-4</li>
          <li>10-Trace-5</li>
        </ul>
        <p>{{ $t('HelpModal3') }}</p>
        <ul style="list-style-type: none">
          <li>{{ $t('HelpModal4') }}</li>
          <li>{{ $t('HelpModal5') }}</li>
          <li>{{ $t('HelpModal6') }}</li>
        </ul>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  BPagination,
  BFormInput,
  BFormSelect,
  BLink,
  BButton,
  BCard,
  BTable,
  BCol,
  BRow,
  BFormGroup,
  BCollapse,
  BBadge,
  BModal,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BTooltip,
} from 'bootstrap-vue'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import store from '@/store/index'
import vSelect from 'vue-select'
import { TimestampToFlatPickr, FlatPickrToTimestamp } from '@/libs/helpers'
import { config } from '@/shared/app.config'
import SelectProducts from '@/components/selectProducts/SelectProducts.vue'
import flatPickr from 'vue-flatpickr-component'
import DownloadService from '@/shared/services/download-service'
import axios from 'axios'

export default {
  components: {
    BCard,
    BPagination,
    BFormInput,
    BFormSelect,
    BTable,
    BButton,
    BCol,
    BRow,
    BLink,
    BCollapse,
    vSelect,
    BFormGroup,
    BBadge,
    SelectProducts,
    flatPickr,
    BModal,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
  },
  directives: {
    BTooltip
  },
  data() {
    return {
      log: [],
      modalSeeShow: false,
      modalHelpShop: false,
      currentTagID: '',
      currentPage: 1,
      statusTags: config.statusTagsVariants,
      pageLength: 10,
      dir: false,
      visibleFilter: false,
      pages: ['10', '15', '25'],
      client: '',
      serie: '',
      status: '',
      product: '',
      date: '',
      dateConfig: {
        mode: 'range'
      },
      date_ini: '',
      date_fin: '',
      columnsClient: [
        {
          label: this.$t('Code'),
          key: 'code',
        },
        {
          label: this.$t('Estado'),
          key: 'status',
        },
        {
          label: this.$t('Equipo'),
          key: 'object',
        },
        {
          label: this.$t('Fecha'),
          key: 'created_at',
        },
        {
          label: this.$t('Acciones'),
          key: 'actions',
          class: 'text-right',
        },
      ],
      columns: [
        {
          label: this.$t('Code'),
          key: 'code',
        },
        {
          label: this.$t('Estado'),
          key: 'status',
        },
        {
          label: this.$t('Activo'),
          key: 'object',
        },
        {
          label: this.$t('Fecha'),
          key: 'created_at',
        },
        {
          label: this.$t('Cliente'),
          key: 'client',
        },
        {
          label: this.$t('Acciones'),
          key: 'actions',
          class: 'text-right',
        },
      ],
      searchTerm: '',
    }
  },
  computed: {
    ...mapGetters({
      currentCompany: 'companies/getCurrentCompany',
      currentClient: 'clients/getCurrentClient',
      items: 'tags/getItems',
      totalItems: 'tags/getTotalItems',
      listClients: 'clients/getItems',
      listSeries: 'series/getSelectSeries',
      currentUser: 'auth/getUser',
      role: 'auth/getRole',
      selectStatus: 'tags/getSelectStatus',
      getCurrentLanguage: 'languages/getCurrentLanguage',
    }),
    imageCurrentTag() {
      return (this.currentTagID) ? `${config.webApiBase}/getqr/${this.currentTagID}` : ''
    },
    seleccionarcolumnas() {
      if (this.currentClient) {
        return this.columnsClient
      }
      return this.columns
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true
        return this.dir
      }
      this.dir = false
      return this.dir
    },
  },
  watch: {
    client() {
      this.currentPage = 1
      this.chargeData()
    },
    serie() {
      this.currentPage = 1
      this.chargeData()
    },
    status() {
      this.currentPage = 1
      this.chargeData()
    },
    product() {
      this.currentPage = 1
      this.chargeData()
    },
    date() {
      const range = this.date.split(' a ')

      this.date_ini = FlatPickrToTimestamp(range[0])
      if (range.length > 1) {
        this.date_fin = FlatPickrToTimestamp(range[1])
      } else {
        this.date_fin = ''
      }
      this.currentPage = 1
      this.chargeData()
    },
  },
  methods: {
    ...mapActions({
      list: 'tags/getListTags',
      export: 'tags/export',
      delete: 'tags/delete',
      getListClients: 'clients/getListClients',
      getSelectSeries: 'series/selectSeries',
      getSelectStatus: 'tags/selectStatus',
    }),
    chargeData() {
      let cliente = this.currentClient ? this.currentClient.id : ''
      if (this.role !== 'admin_cliente' && cliente === '') {
        cliente = this.client ? this.client.id : ''
      }
      const serie = this.serie ? this.serie.id : ''
      const status = this.status ? this.status.id : ''
      const product = this.product ? this.product.id : ''

      this.list({
        client: cliente,
        serie,
        status,
        product,
        page: this.currentPage,
        per_page: this.pageLength,
        search: this.searchTerm,
        date_ini: this.date_ini,
        date_fin: this.date_fin,
      })
    },
    ...mapMutations({
      loading: 'app/loading',
    }),
    async download() {
      let cliente = this.currentClient ? this.currentClient.id : ''
      if (this.role !== 'admin_cliente' && cliente === '') {
        cliente = this.client ? this.client.id : ''
      }
      const serie = this.serie ? this.serie.id : ''
      const status = this.status ? this.status.id : ''
      const product = this.product ? this.product.id : ''

      this.loading(true)
      try {
        const response = await axios({
          url: `${config.webApiBase}/tags/export`,
          data: {
            client_id: cliente,
            serie_id: serie,
            status_tag_id: status,
            product_id: product,
            page: this.currentPage,
            per_page: this.pageLength,
            search: this.searchTerm,
            date_ini: this.date_ini,
            date_fin: this.date_fin,
            lang: this.getCurrentLanguage,
          },
          method: 'POST',
          responseType: 'blob',
        })
        DownloadService.resolveAndDownloadBlob(response.data, 'Tags', 'csv')
      } catch (error) {
        this.$toast.error('Error en la descarga del listado. Por favor inténtelo de nuevo mas tarde.')
      }
      this.loading(false)
    },
    handleSearch() {
      this.currentPage = 1
      this.chargeData()
    },
    handleChangePage(page) {
      this.currentPage = page
      this.chargeData()
    },
    handlePageChange() {
      this.currentPage = 1
      this.chargeData()
    },
    onSortChange(params) {
      this.currentPage = 1
      this.chargeData()
    },
    toDate(f) {
      return f ? TimestampToFlatPickr(f) : ''
    },
    seeTag(tag) {
      this.currentTagID = tag.id
      this.modalSeeShow = true
    },
    seeHelp() {
      this.modalHelpShop = true
    },
    downloadItem(url, label) {
      axios.get(url, { responseType: 'blob' })
          .then(response => {
            const blob = new Blob([response.data], { type: 'image/png' })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = label
            link.click()
            URL.revokeObjectURL(link.href)
          }).catch(console.error)
    },
    deleteTag(id, name) {
      console.log(name)
      this.$bvModal
          .msgBoxConfirm(this.$t('EliminarConfirm', { nombre: name }), {
            bodyClass: 'bodyDelete',
            footerClass: 'footerDelete',
            okVariant: 'danger',
            okTitle: this.$t('Eliminar'),
            cancelTitle: this.$t('Cancelar'),
            cancelVariant: 'outline-danger',
            centered: true,
          })
          .then(value => {
            if (value === true) {
              this.delete(id)
            }
          })
    },
  },
  async created() {
    const clientId = this.currentClient ? this.currentClient.id : ''
    this.getListClients({
      company: this.currentCompany.id,
      page: 1,
      per_page: 999999,
      search: '',
    })
    await this.getSelectStatus()
    await this.getSelectSeries({ client_id: clientId })
    await this.chargeData()
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.dropdown-user{
  list-style-type: none;
}
[dir] .b-nav-dropdown .dropdown-toggle::after{
  display: none;
}
</style>
