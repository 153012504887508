export const config = {
  defaultLocale: 'es',
  locales: ['es', 'en', 'fr'],
  localesFlatPicker: {
    es: 'es',
    en: 'en',
    fr: 'fr',
  },
  priorities: [
    {
      label: 'low',
      value: 'low',
    },
    {
      label: 'medium',
      value: 'medium',
    },
    {
      label: 'high',
      value: 'high',
    },
    {
      label: 'urgent',
      value: 'urgent',
    }],
  statusAssetsVariants: {
    ready: 'light-info',
    free: 'light-info',
    use: 'light-success',
    low: 'light-dark',
    incidence: 'light-danger',
  },
  statusSolarsVariants: {
    ready: 'light-success',
    free: 'light-dark',
    inactive: 'light-danger',
    use: 'light-warning',
    reserved: 'light-warning',
    low: 'light-danger',
    incidence: 'light-danger',
    expired_maintenance: 'light-dark'
  },
  statusInstallationsVariants: {
    ready: 'light-success',
    free: 'light-dark',
    inactive: 'light-danger',
    use: 'light-warning',
    reserved: 'light-warning',
    low: 'light-danger',
    incidence: 'light-danger',
    expired_maintenance: 'light-dark'
  },
  statusEpisVariants: {
    ready: 'light-info',
    free: 'light-info',
    use: 'light-success',
    low: 'light-dark',
    incidence: 'light-danger',
  },
  statusTagsVariants: {
    free: 'light-dark',
    use: 'light-success',
    low: 'light-danger',
    blocked: 'light-danger',
  },
  statusTasksVariants: {
    to_do: 'light-danger',
    in_progress: 'light-warning',
    complete: 'light-success',
    closed: 'light-dark',
    incidence: 'light-info',
  },
  statusPriorityVariants: {
    "Very High": 'light-danger',
    "High": 'light-danger',
    "Medium": 'light-warning',
    "Low": 'light-dark',
    "Undefined": 'light-info',
  },
  statusAlertsVariants: {
    Active: 'light-danger',
    Assigned: 'light-warning',
    Inactive: 'light-success',
    OnHold: 'light-info',
  },
  statusAlerts: [
    {
      label: 'Active',
      value: 'Active',
    },
    {
      label: 'Assigned',
      value: 'Assigned',
    },
    {
      label: 'Inactive',
      value: 'Inactive',
    },
    {
      label: 'OnHold',
      value: 'OnHold',
    }],
  typeAlerts: [
    {
      label: 'Communications',
      value: 'Communications',
    },
    {
      label: 'Production',
      value: 'Production',
    },
    {
      label: 'Scheduled',
      value: 'Scheduled',
    },
    {
      label: 'Stop',
      value: 'Stop',
    },
    {
      label: 'Warning',
      value: 'Warning',
    },
    {
      label: 'Preventive',
      value: 'Preventive',
    },
    {
      label: 'Finding',
      value: 'Finding',
    }],
  severity: [
    {
      label: 'Very High',
      value: 'Very High',
    },
    {
      label: 'High',
      value: 'High',
    },
    {
      label: 'Medium',
      value: 'Medium',
    },
    {
      label: 'Low',
      value: 'Low',
    }],
  statusTasksVariantsHex: {
    to_do: '#ea5455',
    in_progress: '#ff9f43',
    complete: '#28c76f',
    closed: '#82868b',
    incidence: '#ea5455',
    inbox: '#82868b'
  },
  nameApp: 'FotoTrace',
  webApiBase: process.env.VUE_APP_ROOT_API,
  placeholder: process.env.VUE_APP_IMAGE_PLACEHOLDER,
  placeholderDocuments: process.env.VUE_APP_IMAGE_PLACEHOLDER_DOCUMENTS,
}
