<template>
  <b-card>
    <b-row>
      <b-col cols="12">
        <CustomFormProfileForm
          ref="custom-form-profile-form"
          v-model="profileForm"
        />
      </b-col>
      <b-col cols="12">
        <CustomFormElementList v-model="formElementsList" @handleSubmit="handleSubmit" />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import CustomFormProfileForm from "@/components/customForms/form/CustomFormProfileForm.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import CustomFormElementList from "@/components/customForms/list/CustomFormElementList.vue";

export default {
  components: {
    CustomFormElementList,
    CustomFormProfileForm,
  },
  data() {
    return {
      profileForm: {},
      formElements: [
        {
          title: 'Default',
          elements: [
            {
              alias: "title_input",
              name: "Título",
              id: 7,
              order: 1,
              config: { identifier: "title" },
              default: true,
              observations: false,
            },
            {
              alias: "hidden",
              name: "Oculto",
              id: 6,
              order: 2,
              config: { identifier: "form_name" },
              default: true,
              observations: false,
            },
            {
              alias: "hidden",
              name: "Oculto",
              id: 6,
              order: 3,
              config: { identifier: "assets" },
              default: true,
              observations: false,
            },
            {
              alias: "hidden",
              name: "Oculto",
              id: 6,
              order: 4,
              config: { identifier: "task_id" },
              default: true,
              observations: false,
            },
            {
              alias: "hidden",
              name: "Oculto",
              id: 6,
              order: 5,
              config: { identifier: "user" },
              default: true,
              observations: false,
            },
            {
              alias: "hidden",
              name: "Oculto",
              id: 6,
              order: 7,
              config: { identifier: "epis" },
              default: true,
              observations: false,
            },
            {
              alias: "hidden",
              name: "Oculto",
              id: 6,
              order: 8,
              config: { identifier: "installations" },
              default: true,
              observations: false,
            },
            {
              alias: "hidden",
              name: "Oculto",
              id: 6,
              order: 9,
              config: { identifier: "solars" },
              default: true,
              observations: false,
            },
          ]
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      role: "auth/getRole",
      selectEvents: "events/getItems",
      currentCompany: "companies/getCurrentCompany",
      currentClient: "clients/getCurrentClient",
    }),
    formElementsList: {
      get() {
        return this.formElements
      },
      set(value) {
        this.formElements = value;
      },
    },
  },
  watch: {
    profileForm: {
      handler(value) {
        if (value?.events?.length > 0) {
          const isRevision = value.events.some(
            ({ alias }) => alias === "review"
            );

            const existField = this.formElements[0].elements.some(
              (element) => element.alias === "check" && element.default
            );

          if (isRevision && !existField) {
            this.formElements[0].elements.push({
              alias: "check",
              name: "Checkbox",
              id: 1,
              order: 6,
              config: {
                identifier: "apto",
                question: "Resultado",
                showpdf: true,
                required: true,
                options: [
                  { label: "Apto", value: "Apto" },
                  { label: "No Apto", value: "No" },
                ],
              },
              default: true,
              observations: false,
            });
          }

          if (!isRevision && existField) {
            this.deleteElementForRevision()
          }
        } else {
          this.deleteElementForRevision()
        }
      },
      deep: true
    },
  },
  methods: {
    ...mapActions({
      create: "forms/create",
      getSelectEvents: "events/getList",
    }),
    deleteElementForRevision() {
      const element = this.formElements[0].elements.findIndex(
        (element) => element.default && element.alias === "check"
      );

      if(element > 0) {
        this.formElements[0].elements.splice(element, 1);
      }
    },
    async handleSubmit() {
      const validForm = await this.$refs["custom-form-profile-form"].validate();
      if (!validForm) {
        return;
      }

      if (this.formElements.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error formulario",
            text: "Debes añadir al menos un elemento de formulario",
            variant: "danger",
          },
        });

        return;
      }

      this.create({ form: this.createFormData() });
    },
    createFormData() {
      const data = new FormData();

      this.profileForm.events.forEach((element) => {
        data.append("events[]", element.id);
      });

      const structureFormElements = this.formElements.flatMap((formElement, sectionIndex) => {
        formElement.elements = formElement.elements.map((element) => {
          element.order = `${sectionIndex}-${element.order}`
          element.block_info = {
            id: sectionIndex,
            name: formElement.title,
          }
          return element
        })
        return formElement.elements
      });

      structureFormElements.forEach((element) => {
        data.append("formElements[]", JSON.stringify(element));
      })

      if (this.profileForm.company) {
        data.append("company_id", this.profileForm.company.id);
      }
      if (this.profileForm.client) {
        data.append("client_id", this.profileForm.client.id);
      }
      data.append("name", this.profileForm.name);

      data.append("title", this.profileForm.title || '');
      data.append("active", this.profileForm.active || 0);
      data.append("description", this.profileForm.description || '');

      return data;
    },
  },
  async mounted() {
    if (this.role === "admin_cliente") {
      await this.getSelectEvents();
      const reviewEvent = this.selectEvents?.find(
        ({ alias }) => alias === "review"
      );
      Vue.set(this.profileForm, "events", [reviewEvent]);
      Vue.set(this.profileForm, "client", this.currentClient);
      Vue.set(this.profileForm, "company", this.currentCompany);
    }
  },
};
</script>
