<template>
  <div>
    <BaseModal
        v-model="model"
        :title="$t('NewExternalActions')"
        size="md"
        :loading="loading"
        @confirm-button-click="handleSaveButtonClick"
    >
      <ExternalActionForm v-model="externalAction" ref="external-action-form-component"  />
    </BaseModal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { FlatPickrToTimestampWithTime } from "@/libs/helpers";
import BaseModal from "@/components/ui/modal/BaseModal.vue";
import ExternalActionForm from "@/components/actions/form/ExternalActionForm.vue";

export default {
  name: "CreateExternalActionModal",
  components: {
    BaseModal,
    ExternalActionForm,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    object_type: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      loading: false,
      externalAction: {},
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        if(!value) {
          this.$refs["external-action-form-component"].clearForm()
        }
        this.$emit("input", value);
      },
    },
  },
  methods: {
    ...mapActions({
      create: "externalEvents/createEvent",
      getList: "externalEvents/getListAsset",
    }),
    createFormData(formData) {
      const { documentation } = this.$refs["external-action-form-component"].getDocumentationData()
      const asset_id = this.$route.params.id

      const data = new FormData();

      documentation.forEach((document) => {
        data.append("documents[]", document)
      });

      data.append("object_id", asset_id)
      data.append("type_object", this.object_type)
      data.append("type", formData.event.alias)
      data.append("responsible", formData.responsible)
      data.append("created_at", FlatPickrToTimestampWithTime(formData.created_at))
      return data
    },
    async handleSaveButtonClick() {
      const formValid = await this.$refs["external-action-form-component"].validateForm()

      if (!formValid) {
        return
      }
      this.loading = true

      try {
        const contactData = this.createFormData(this.externalAction)

        await this.create(contactData)
      } finally {
        this.loading = false
        await this.getList({
          asset_id: this.$route.params.id,
          page: 1,
          per_page: 10,
          search: '',
          type_object: this.object_type,
        });
        this.model = false
      }
    },
  },
};
</script>
