import axios from 'axios'
import { config } from '@/shared/app.config'

const RESOURCE_NAME = '/clients'
const RESOURCE_NAME_NOTIF = '/notifications'

export default {
  list(idCompany = '', page, per_page, search = '') {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/list`, {
      company_id: idCompany,
      page,
      per_page,
      search,
    })
  },
  edit(id, client) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/${id}`, client)
  },
  create(client) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}`, client)
  },
  getClient(id) {
    return axios.get(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
  getLists(id) {
    return axios.get(`${config.webApiBase + RESOURCE_NAME}/get/lists/${id}`)
  },
  notifymanual(notify) {
    return axios.post(
        `${config.webApiBase + RESOURCE_NAME_NOTIF}/manually`,
        notify,
    )
  },
  notifyauto(notify) {
    return axios.post(
        `${config.webApiBase + RESOURCE_NAME_NOTIF}/automatic`,
        notify,
    )
  },
  import(excel) {
    return axios.post(`${config.webApiBase}/import/client`, excel)
  },
}
