<template>
  <div>
    <validation-observer ref="editSolar">
      <b-form
        v-if="solarPrepare"
        class="mt-2"
        @submit.prevent="handleSubmit"
        @keydown.enter="handleSubmit"
      >
        <b-card>
          <!-- details installation -->
          <b-row>
            <b-col cols="12">
              <b-card class="cursor-pointer mb-1" v-b-toggle.collapse-details>
                <div class="d-flex justify-content-between">
                  <span class="mb-0">
                    {{ $t("DetallesSolar") }}
                  </span>
                  <span class="when-closed">
                    <feather-icon icon="ChevronUpIcon" size="18" />
                  </span>
                  <span class="when-opened">
                    <feather-icon icon="ChevronDownIcon" size="18" />
                  </span>
                </div>
              </b-card>
              <b-collapse id="collapse-details" visible class="mx-1 mb-2">
                <b-row>
                  <b-col sm="6">
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('FacilityName')"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('FacilityName')"
                        label-for="solar-name"
                      >
                        <b-form-input
                          v-model="name"
                          name="name"
                          :placeholder="$t('FacilityName')"
                        />
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <b-col sm="6">
                    <validation-provider
                        #default="{ errors }"
                        :name="$t('ProductoInstalación')"
                        rules="required"
                    >
                      <b-form-group
                          :label="$t('ProductoInstalación')"
                          label-for="solar-product"
                      >
                        <SelectProducts v-model="product" type="solar" />
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <b-col sm="6">
                    <b-form-group
                        :label="$t('FacilityPowerType')"
                        label-for="facility_power_type"
                    >
                      <div class="custom-switch-wrapper">
                        <input type="checkbox" id="power_type" v-model="power_type" class="custom-switch">
                        <label for="power_type" class="custom-switch-label">
                        <span class="custom-switch-inner">
                          <span class="switch-text switch-text-off">Wp</span>
                          <span class="switch-text switch-text-on">MW</span>
                        </span>
                          <span class="custom-switch-switch"></span>
                        </label>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col sm="6">
                    <b-form-group
                      :label="$t('FacilityPower')"
                      label-for="facility_power"
                    >
                      <b-form-input
                          v-model="power"
                          name="name"
                          type="text"
                          :placeholder="$t('FacilityPower')"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col sm="6">
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('Estado')"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('Estado')"
                        label-for="solar-status"
                      >
                        <v-select
                          v-model="status"
                          label="alias"
                          :filterable="false"
                          :searchable="false"
                          :options="selectStatus"
                          :placeholder="$t('Estado')"
                          :readonly="true"
                          :disabled="true"
                        >
                          <template slot="option" slot-scope="option">
                            {{ $t("status.solars." + option.alias) }}
                          </template>
                          <template slot="selected-option" slot-scope="option">
                            {{ $t("status.solars." + option.alias) }}
                          </template>
                        </v-select>
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <b-col sm="6">
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('FechaCompra')"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('FechaCompra')"
                        label-for="solar-purchase_date"
                      >
                        <flat-pickr
                          v-model="purchase_date"
                          name="purchase_date"
                          class="form-control"
                        />
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <b-col sm="6">
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('Address')"
                      rules=""
                    >
                      <b-form-group :label="$t('Address')" label-for="address">
                        <b-input-group>
                          <b-form-input
                            v-model="address"
                            name="address"
                            :placeholder="$t('Address')"
                          />
                        </b-input-group>
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" class="mb-2" >
                    <p>{{ $t('TextClickMap') }}</p>
                    <l-map ref="map" :zoom="zoom" :center="center" @click="addMarker" >
                      <l-tile-layer :url="url" />
                      <l-marker
                          v-if="marker"
                          :lat-lng="marker"
                      >
                      </l-marker>
                    </l-map>
                  </b-col>
                  <b-col sm="12">
                    <b-form-group
                      :label="$t('FacilityImages')"
                      label-for="facility-images"
                    >
                      <ImageDropzone
                        ref="documents"
                        :files="documents"
                        maxFiles="10"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-col>
          </b-row>
          <!-- client -->
          <b-row>
            <b-col cols="12">
              <b-card class="cursor-pointer mb-1" v-b-toggle.collapse-users>
                <div class="d-flex justify-content-between">
                  <span class="mb-0">
                    {{ $t("ClientInformation") }}
                  </span>
                  <span class="when-closed">
                    <feather-icon icon="ChevronUpIcon" size="18" />
                  </span>
                  <span class="when-opened">
                    <feather-icon icon="ChevronDownIcon" size="18" />
                  </span>
                </div>
              </b-card>
              <!-- collapse -->
              <b-collapse id="collapse-users" class="mx-1 mb-2">
                <b-row class="pt-1">
                  <!-- subclient -->
                  <b-col sm="6">
                    <b-form-group
                      :label="$t('Cliente')"
                      label-for="client-name"
                    >
                      <SubClientSelect
                        id="selectSubClient"
                        :value="selectedSubClient"
                        @input="addSubClient"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- contact Person -->
                  <template v-if="selectedSubClient">
                    <b-col sm="6">
                      <b-form-group
                        label-for="selectedCLient"
                        :label="$t('ContactPersons')"
                      >
                        <ContactPersonSelect
                          id="selectedCLient"
                          :subclient="selectedSubClient.id"
                          :value="selectedCLient"
                          @input="addContact"
                          :key="selectedSubClient.id"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" class="mt-2">
                      <template v-if="selectedCLient.length">
                        <EditableContactPersonList
                          :items="selectedCLient"
                          @delete="handleContactDelete"
                        />
                      </template>
                    </b-col>
                  </template>
                </b-row>
              </b-collapse>
            </b-col>
          </b-row>
          <!-- components-->
          <b-row>
            <b-col cols="12">
              <b-card
                  v-b-toggle.collapse-components
                  class="cursor-pointer mb-1"
              >
                <div class="d-flex justify-content-between">
                  <span class="mb-0">
                    {{ $t("assets") }}
                  </span>
                  <span class="when-closed">
                    <feather-icon icon="ChevronUpIcon" size="18" />
                  </span>
                  <span class="when-opened">
                    <feather-icon icon="ChevronDownIcon" size="18" />
                  </span>
                </div>
              </b-card>
              <b-collapse id="collapse-components" class="mx-1 mb-2">
                <b-row>
                  <b-col sm="12" xl="6">
                    <AssetsSelect v-model="assets" :solar_id="this.$route.params.id"/>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-col>
          </b-row>
          <!-- manufacturer documents -->
          <b-row>
            <b-col cols="12">
              <b-card class="cursor-pointer mb-1" v-b-toggle.manufacturer-documents>
                <div class="d-flex justify-content-between">
                  <span class="mb-0">
                    {{ $t("ManufacturesDocs") }}
                  </span>
                  <span class="when-closed">
                    <feather-icon icon="ChevronUpIcon" size="18" />
                  </span>
                  <span class="when-opened">
                    <feather-icon icon="ChevronDownIcon" size="18" />
                  </span>
                </div>
              </b-card>
              <b-collapse id="manufacturer-documents" class="mx-1 mb-2">
                <b-row>
                  <b-col sm="12">
                    <b-form-group :label="$t('ProductionAccessLinkInfo')" label-for="production-access">
                    <validation-provider #default="{ errors }" rules="url">
                      <b-form-input
                        id="production-access"
                        v-model="production_access"
                        name="production-access"
                        :placeholder="$t('ProductionAccessLink')"
                      />
                      <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  </b-col>
                  <b-col sm="12">
                    <b-form-group
                      :label="$t('ManufacturesDocs')"
                      label-for="info_docs"
                    >
                      <ImageDropzone
                        ref="info_docs"
                        :files="info_docs"
                        maxFiles="10"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-col>
          </b-row>
          <!-- cover -->
          <b-row>
            <b-col cols="12">
              <b-card class="cursor-pointer mb-1" v-b-toggle.cover-info>
                <div class="d-flex justify-content-between">
                  <span class="mb-0">
                    {{ $t("Cover") }}
                  </span>
                  <span class="when-closed">
                    <feather-icon icon="ChevronUpIcon" size="18" />
                  </span>
                  <span class="when-opened">
                    <feather-icon icon="ChevronDownIcon" size="18" />
                  </span>
                </div>
              </b-card>
              <b-collapse id="cover-info" class="mx-1 mb-2">
                <b-col sm="12" class="d-flex">
                  <b-form-group :label="$t('CoverType')" label-for="cover-type">
                    <b-form-radio-group
                      v-model="cover_type"
                      :options="optionsCoverType"
                      name="cover-type"
                      class="demo-inline-spacing"
                    />
                    <b-input-group
                      v-if="cover_type === 'tilt'"
                      class="mt-2 d-flex"
                    >
                      <b-form-input
                        v-model="angle"
                        name="angle"
                        type="number"
                        :placeholder="$t('Angle')"
                      />
                      <b-input-group-append is-text>
                        <b-img
                          fluid
                          :src="AngleIcon"
                          alt="AngleIcon"
                          style="height: 25px; width: 25px"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col sm="12">
                  <b-form-group
                    :label="$t('CoverObservations')"
                    label-for="cover-observations"
                  >
                    <quill-editor
                      v-model="observations"
                      :options="editorOption"
                    />
                  </b-form-group>
                </b-col>
              </b-collapse>
            </b-col>
          </b-row>
          <!-- security -->
          <b-row>
            <b-col cols="12">
              <b-card class="cursor-pointer mb-1" v-b-toggle.security-info>
                <div class="d-flex justify-content-between">
                  <span class="mb-0">
                    {{ $t("Security") }}
                  </span>
                  <span class="when-closed">
                    <feather-icon icon="ChevronUpIcon" size="18" />
                  </span>
                  <span class="when-opened">
                    <feather-icon icon="ChevronDownIcon" size="18" />
                  </span>
                </div>
              </b-card>
              <b-collapse id="security-info" class="mx-1 mb-2">
                <b-row>
                  <b-col sm="6" v-if="currentCompany.id === 3">
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('Coste')"
                      rules=""
                    >
                      <b-form-group
                        :label="$t('Coste')"
                        label-for="equipos-coste"
                      >
                        <b-input-group append="€">
                          <b-form-input
                            v-model="coste"
                            name="equipos-coste"
                            type="number"
                            :placeholder="$t('Coste')"
                          />
                        </b-input-group>
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <b-col sm="12">
                    <b-form-group
                      :label="$t('Línea de vida')"
                      label-for="linea_vida"
                      class="d-flex align-items-center gap-1"
                    >
                      <b-form-checkbox
                        id="linea_vida"
                        v-model="linea_vida"
                        name="check-button-linea_vida"
                        switch
                        inline
                      />
                    </b-form-group>
                    <b-form-group
                      :label="$t('Barandillas de seguridad')"
                      label-for="barandillas_seguridad"
                      class="d-flex align-items-center gap-1"
                    >
                      <b-form-checkbox
                        id="barandillas_seguridad"
                        v-model="barandillas_seguridad"
                        name="check-button-barandillas_seguridad"
                        switch
                        inline
                      />
                    </b-form-group>

                    <b-form-group
                      :label="$t('Redes de contanción')"
                      label-for="redes_contencion"
                      class="d-flex align-items-center gap-1"
                    >
                      <b-form-checkbox
                        id="redes_contencion"
                        v-model="redes_contencion"
                        name="check-button-redes_contencion"
                        switch
                        inline
                      />
                    </b-form-group>

                    <b-form-group
                      :label="$t('Zona de trabajo delimitada')"
                      label-for="zona_delimitada"
                      class="d-flex align-items-center gap-1"
                    >
                      <b-form-checkbox
                        id="zona_delimitada"
                        v-model="zona_delimitada"
                        name="check-button-zona_delimitada"
                        switch
                        inline
                      />
                    </b-form-group>
                    <b-form-group
                      :label="$t('SecurityElements')"
                      label-for="security-elements"
                    >
                      <quill-editor
                        v-model="security_elements"
                        :options="editorOption"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-col>
          </b-row>
          <!-- submit button -->
          <b-row>
            <b-col cols="12 text-right">
              <b-button type="submit" variant="primary" class="mt-2 mr-1">
                {{ $t("Guardar") }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { BFormCheckbox } from "bootstrap-vue";
import { quillEditor } from "vue-quill-editor";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import { FlatPickrToTimestamp, TimestampToFlatPickr } from "@/libs/helpers";
import ImageDropzone from "@/components/elements/ImageDropzone/ImageDropzone.vue";
import vSelect from "vue-select";
import SelectProducts from "@/components/selectProducts/SelectProducts.vue";
import BaseSelect from "@/components/ui/select/BaseSelect.vue";
import { LMap, LMarker, LTileLayer } from "vue2-leaflet"
import {Icon, latLng} from "leaflet"
import "leaflet/dist/leaflet.css"
import AssetsSelect from "@/components/assets/select/AssetsSelect.vue";
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
})
import ContactPersonSelect from "@/components/contactPerson/select/ContactPersonSelect.vue";
import SubClientSelect from "@/components/subClients/select/SubClientSelect.vue";
import EditableContactPersonList from "@/components/contactPerson/list/EditableContactPersonList.vue";

export default {
  components: {
    SelectProducts,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    quillEditor,
    vSelect,
    ImageDropzone,
    BaseSelect,
    BFormCheckbox,
    LMap,
    LTileLayer,
    LMarker,
    SubClientSelect,
    ContactPersonSelect,
    EditableContactPersonList,
    AssetsSelect
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 5,
      center: latLng(40.2085, -3.713),
      latitude: '',
      longitude: '',
      // townsKey: new Date(),
      AngleIcon: require("@/assets/images/icons/angle.svg"),
      assets: [],
      selectedCLient: [],
      selectedSubClient: null,

      client_contact_persons: [],
      contactPerson: {
        name: "",
        email: "",
        phone: "",
      },

      name: "",
      client_name: "",
      client_phone: "",
      power: "",
      power_type: true,
      cover_type: "",
      observations: "",
      linea_vida: false,
      barandillas_seguridad: false,
      redes_contencion: false,
      zona_delimitada: false,
      security_elements: "",
      angle: "",
      address: "",
      purchase_date: "",
      production_access: "",
      facility_date: "",
      pedido_num_id: "",
      // location: '',
      documents: [],
      info_docs: [],
      last_review: "",
      description: "",
      next_review: "",
      product: "",
      user: "",
      status: "",
      // province: "",
      // town: "",

      optionsCoverType: [
        { text: this.$t("Flat"), value: "flat" },
        { text: this.$t("Tilt"), value: "tilt" },
      ],

      required,
      email,
      solarPrepare: false,
      // location: '',
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
          ],
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      localeDatePicker: "languages/getLocaleDatePicker",
      selectStatus: "solars/getSelectStatus",
      currentClient: "clients/getCurrentClient",
      currentCompany: "companies/getCurrentCompany",
      selectDepartments: "departments/getSelectDepartments",
      solar: "solars/getSolar",
    }),
    marker() {
      return latLng(this.latitude, this.longitude)
    },
  },

  methods: {
    ...mapActions({
      edit: "solars/edit",
      getSolar: "solars/getSolar",
      getSelectStatus: "solars/selectStatus",
      getSelectDepartments: "departments/selectDepartments",
    }),
    async handleSubmit() {
      this.$refs.editSolar.validate().then(async (success) => {
        if (success) {
          const formData = this.createFormData();
          if (formData) {
            await this.edit({ id: this.solar.id, solar: formData });
          }
        } else{
          Object.keys(this.$refs.editSolar.refs).forEach((key) => {
            const id = this.$refs.editSolar.refs[key].$parent.$el.id

            const a = document.getElementById(id);
              a.scrollIntoView({
                  behavior: "smooth",
                  block: "end"
              })
            if(this.$refs.editSolar.refs[key].errors.length > 0) {
              this.$refs.editSolar.refs[key].$parent.show = true
            }
          })
        }
      });
    },
    addMarker(event) {
      this.latitude = event.latlng.lat
      this.longitude = event.latlng.lng
    },
    addSubClient(value) {
      if (this.selectedSubClient !== value) {
        this.selectedCLient = []; //vaciar el array de contactos
      }
      this.selectedSubClient = value;
    },
    addContact(value) {
      value.checked = value.app_access === 1; //trucazo para el checkbox
      this.selectedCLient.push(value);
    },
    createFormData() {
      let data = this.$refs.documents.getFormData("documents");
      this.$refs.info_docs.getFormData("info_docs", data);

      // const data = new FormData()
      if (this.last_review) {
        data.append("last_review_date", FlatPickrToTimestamp(this.last_review));
      }
      if (this.next_review) {
        data.append("next_review_date", FlatPickrToTimestamp(this.next_review));
      } else {
        data.append("next_review_date", null);
      }
      if (this.facility_date) {
        data.append("purchase_date", FlatPickrToTimestamp(this.facility_date));
      } else {
        data.append("purchase", null);
      }

      if (this.selectedSubClient) {
        data.append("subclient_id", this.selectedSubClient.id);
      }

      if (this.selectedCLient.length > 0) {
        this.selectedCLient.forEach((element) => {
          if (element && element !== null) {
            data.append("users_contact[]", element.id);
          }
        });
      } else {
        data.append("users_contact", "");
      }

      if (this.assets.length > 0) {
        this.assets.forEach((element) => {
          data.append("assets[]", JSON.stringify(element.id));
        });
      }

      data.append("name", this.name);
      // data.append("client_name", this.client_name);
      data.append("power", this.power);
      if(this.power_type){
        data.append("power_type", 'Wp');
      }else{
        data.append("power_type", 'MW');
      }

      data.append("cover_type", this.cover_type);
      data.append("observations", this.observations);
      data.append("security_elements", this.security_elements);

      data.append("status_solar_id", 1);
      data.append("product_id", this.product.id);
      data.append("quantity", 1);
      data.append("pedido_num_id", this.pedido_num_id);
      data.append("coste", this.coste);
      data.append("address", this.address);
      data.append("purchase_date", FlatPickrToTimestamp(this.purchase_date));
      data.append("angle", this.angle);
      data.append("linea_vida", this.linea_vida ? 1 : 0);
      data.append("barandillas_seguridad", this.barandillas_seguridad ? 1 : 0);
      data.append("redes_contencion", this.redes_contencion ? 1 : 0);
      data.append("zona_delimitada", this.zona_delimitada ? 1 : 0);
      data.append('latitude', this.latitude)
      data.append('longitude', this.longitude)
      // data.append("province_id", this.province?.id);
      // data.append("town_id", this.town?.id);

      data.append("client_id", this.currentClient.id);
      data.append("production_access", this.production_access);

      return data;
    },
    handleContactDelete(payload) {
      const { index, item } = payload;
      this.selectedCLient.splice(index, 1);
    },

    setData() {
      if (this.solar.last_review) {
        this.last_review = TimestampToFlatPickr(this.solar.last_review);
      }
      if (this.solar.next_review) {
        this.next_review = TimestampToFlatPickr(this.solar.next_review);
      }
      if (this.solar.purchase_date) {
        this.purchase_date = TimestampToFlatPickr(this.solar.purchase_date);
      }
      if (this.solar.documents.length > 0) {
        this.solar.documents.forEach((element, index) => {
          const file = {
            name: element.name,
            url: element.path,
            urlDelete: `/solars/delete_doc/${this.solar.id}/${element.id}`,
          };
          this.documents.push(file);
        });
      }
      if (this.solar.info_docs.length > 0) {
        this.solar.info_docs.forEach((element, index) => {
          const file = {
            name: element.name,
            url: element.path,
            urlDelete: `/solars/delete_doc/${this.solar.id}/${element.id}`,
          };
          this.info_docs.push(file);
        });
      }
      if (this.solar.user) {
        this.user = this.solar.user;
      }
      if (this.solar.status) {
        this.status = this.solar.status;
      }
      if (this.solar.product) {
        this.product = this.solar.product;
      }
      if (this.solar.name) {
        this.name = this.solar.name;
      }
      if (this.solar.power) {
        this.power = this.solar.power;
      }
      if(this.solar.power_type && this.solar.power_type === 'MW'){
        this.power_type = false;
      }
      if (this.solar.client_name) {
        this.client_name = this.solar.client_name;
      }
      if (this.solar.cover_type) {
        this.cover_type = this.solar.cover_type;
      }
      if (this.solar.observations) {
        this.observations = this.solar.observations;
      }
      if (this.solar.security_elements) {
        this.security_elements = this.solar.security_elements;
      }
      if (this.solar.angle) {
        this.angle = this.solar.angle;
      }
      if (this.solar.address) {
        this.address = this.solar.address;
      }
      if(this.solar.production_access) {
        this.production_access = this.solar.production_access
      }
      if (this.solar.assets) {
        this.assets = this.solar.assets;
      }
      //address data
      // if (this.solar.province) {
      //   this.province = this.solar.province;
      //   // this.getSelectTowns({ province_id: this.province.id })
      // }
      // if (this.solar.town) {
      //   this.town = this.solar.town;
      // }
      //security checks
      if (this.solar.linea_vida === 1) {
        this.linea_vida = true;
      }
      if (this.solar.barandillas_seguridad === 1) {
        this.barandillas_seguridad = true;
      }
      if (this.solar.redes_contencion === 1) {
        this.redes_contencion = true;
      }
      if (this.solar.zona_delimitada === 1) {
        this.zona_delimitada = true;
      }
      if (this.solar.location_relation) {
        this.latitude = this.solar.location_relation.latitude
        this.longitude = this.solar.location_relation.longitude
        this.center = latLng(this.latitude, this.longitude)
      }
      //subclient y contacts
      if (this.solar.subclient) {
        this.selectedSubClient = this.solar.subclient;
      }
      if (this.solar.users_contact.length > 0) {
        this.selectedCLient = this.solar.users_contact;
      }
      //done
      this.solarPrepare = true;

    },
  },
  async created() {
    await this.getSolar(this.$route.params.id);
    await this.setData();
    await this.getSelectStatus();
    await this.getSelectDepartments({ clients: [this.currentClient] });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.form-control:disabled {
  background-color: #f7f5f0 !important;
}

.collapsed > .card-body > div > .when-opened,
:not(.collapsed) > .card-body > div > .when-closed {
  display: none;
}

.card-body {
  padding: 20px;
}

.card .collapsed {
  border: 1px solid $secondary !important;
  color: $secondary !important;
}

.card .not-collapsed {
  background-color: $secondary !important;
  color: $primary !important;
}

.custom-switch-wrapper {
  display: flex;
  align-items: center;
}

.custom-switch-wrapper .custom-switch {
  display: none;
}

.custom-switch-wrapper .custom-switch-label {
  position: relative;
  display: block;
  width: 85px;  /* Adjust width to accommodate text */
  height: 35px; /* Adjust height to make it larger */
  background-color: #FFD748;
  border-radius: 25px;
  cursor: pointer;
}

.custom-switch-wrapper .custom-switch-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  padding: 0 10px;
  box-sizing: border-box;
  transition: background-color 0.3s;
}

.custom-switch-wrapper .switch-text {
  font-size: 14px;
  font-weight: bold;
  color: white;
}

.custom-switch-wrapper .custom-switch-switch {
  position: absolute;
  top: 3px;
  left: 5px;
  width: 30px;
  height: 29px;
  background-color: white;
  border-radius: 50%;
  transition: left 0.3s;
}

.custom-switch-wrapper .custom-switch:checked + .custom-switch-label .custom-switch-inner {
  background-color: #FFD748;
}

.custom-switch-wrapper .custom-switch:checked + .custom-switch-label .custom-switch-switch {
  left: 50px; /* Adjust this to match the switch size */
}

.custom-switch-wrapper .custom-switch:checked + .custom-switch-label .switch-text-on {
  opacity: 1;
}

.vue2leaflet-map {
  &.leaflet-container {
    height: 450px;
  }
}
</style>
