<template>
  <div :id="`asset-${asset.index}`" style="border-bottom: 1px solid #ebe9f1;">
    <b-row
      align-h="between"
      align-v="center"
      class="p-2 cursor-pointer"
      @click="collapse = !collapse"
    >
      <b-col class="text-gray-50 font-weight-bold font-small" cols="auto">
        <span class="text-uppercase">({{ asset.total }}) </span>
        <span class="text-uppercase">{{ asset.name }}</span>
      </b-col>
      <b-col cols="auto">
        <FeatherIcon
          v-if="!collapse"
          icon="ChevronDownIcon"
          class="text-primary"
        />
        <FeatherIcon v-else icon="ChevronUpIcon" class="text-primary" />
      </b-col>
    </b-row>
    <b-collapse v-model="collapse">
      <b-overlay :show="loading" variant="white" spinner-variant="primary" rounded="sm" >
        <ListTable 
          v-if="collapse"
          :localMode="true" 
          :showHeader="false"
          :showStadistics="false"
          :assetsId="asset.assets"
          :reload="false"
          :showActions="false"
          @loading="loading = $event"
        />
      </b-overlay>
    </b-collapse>
  </div>
</template>

<script>
import ListTable from '@/views/assets/ListTable'

export default {
  components: {
    ListTable,
  },
  props: {
    asset: {
      type: Object
    }
  },
  data() {
    return {
      collapse: false,
      loading: false
    };
  },
};
</script>
