<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="editAsset">
      <b-form
        v-if="assetPrepare"
        class="mt-2"
        @submit.prevent="handleSubmit"
        @keydown.enter="handleSubmit"
      >
        <b-row>
          <b-col cols="12">
            <h4 class="mb-2">
              {{ $t('DetallesAsset') }}
            </h4>
          </b-col>
          <b-col sm="6">
            <validation-provider
                #default="{ errors }"
                :name="$t('Producto')"
                rules="required"
            >
              <b-form-group
                  :label="$t('Producto')"
                  label-for="asset-product"
              >
                <SelectProducts v-model="product" :readonly="true" type="asset"/>
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
                #default="{ errors }"
                :name="$t('Estado')"
                rules="required"
            >
              <b-form-group
                  :label="$t('Estado')"
                  label-for="asset-status"
              >
                <v-select
                    v-model="status"
                    label="alias"
                    :filterable="false"
                    :searchable="false"
                    :options="selectStatus"
                    :placeholder="$t('Estado')"
                    :readonly="false"
                    :disabled="currentRole && (currentRole !== 'admin_cliente' && currentRole !== 'super_admin' && currentRole !== 'admin_empresa')"
                >
                  <template
                      slot="option"
                      slot-scope="option"
                  >
                    {{ $t('status.assets.' + option.alias) }}
                  </template>
                  <template
                      slot="selected-option"
                      slot-scope="option"
                  >
                    {{ $t('status.assets.' + option.alias) }}
                  </template>
                </v-select>
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('NumeroSerie')"
              rules="required"
            >
              <b-form-group
                :label="$t('NumeroSerie')"
                label-for="asset-serial_number"
              >
                <b-form-input
                  v-model="asset.serial_number"
                  name="serial_number"
                  :placeholder="$t('NumeroSerie')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <!--<b-col sm="6">
            <b-form-group
              :label="$t('NumeroAlbaran')"
              label-for="asset-pedido_num_id"
            >
              <b-form-input
                v-model="asset.pedido_num_id"
                name="pedido_num_id"
                :placeholder="$t('NumeroAlbaran')"
              />
            </b-form-group>
          </b-col>-->
          <b-col sm="6">
            <b-form-group
                :label="$t('FechaCompraEquipo')"
                label-for="asset-purchase_date"
            >
              <flat-pickr
                  v-model="purchase_date"
                  name="purchase_date"
                  class="form-control"
                  readonly="true"
                  :disabled="true"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6" v-if="currentCompany.id === 3">
            <validation-provider
                #default="{ errors }"
                :name="$t('Coste')"
                rules=""
            >
              <b-form-group
                  :label="$t('Coste')"
                  label-for="equipos-coste"
              >
                <b-input-group append="€">
                  <b-form-input
                      v-model="asset.coste"
                      name="equipos-coste"
                      type="number"
                      :placeholder="$t('Coste')"
                  />
                </b-input-group>
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <!--<b-col sm="6">
            <validation-provider
                #default="{ errors }"
                :name="$t('Localizacion')"
                rules=""
            >
              <b-form-group
                  :label="$t('Localizacion origen')"
                  label-for="asset-location"
              >
                <v-select
                    v-model="location"
                    label="name"
                    :filterable="false"
                    :searchable="false"
                    :options="selectLocations"
                    :placeholder="$t('Localizacion')"
                    :disabled="currentRole && (currentRole !== 'admin_cliente' && currentRole !== 'super_admin' && currentRole !== 'admin_empresa')"
                    readonly="readonly"
                >
                  <template
                      slot="option"
                      slot-scope="option"
                  >
                    {{ option.name }}
                  </template>
                  <template
                      slot="selected-option"
                      slot-scope="option"
                  >
                    {{ option.name }}
                  </template>
                </v-select>
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>-->
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('ProximaRevision')"
              rules=""
            >
              <b-form-group
                :label="$t('ProximaRevision')"
                label-for="asset-next_review"
              >
                <flat-pickr
                  v-model="next_review"
                  name="next_review"
                  class="form-control"
                  :readonly="true"
                  :disabled="true"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="12">
            <validation-provider
              #default="{ errors }"
              :name="$t('Descripcion')"
              rules=""
            >
              <b-form-group
                :label="$t('Descripcion')"
                label-for="asset-description"
              >
                <quill-editor
                  v-model="asset.description"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <!--<b-col sm="12">
            <b-form-group
                :label="$t('Documentos')"
                label-for="asset-documents"
            >
              <ImageDropzone
                  ref="documents"
                  :files="files"
                  maxFiles="10"
              />
            </b-form-group>
          </b-col>-->
          <b-col cols="12 text-right">
            <b-button
              type="submit"
              variant="primary"
              class="mt-2 mr-1"
            >
              {{ $t('Enviar') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup,
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import { FlatPickrToTimestamp, TimestampToFlatPickr } from '@/libs/helpers'
import ImageDropzone from '@/components/elements/ImageDropzone/ImageDropzone.vue'
import vSelect from 'vue-select'
import SelectProducts from '@/components/selectProducts/SelectProducts.vue'

export default {
  components: {
    SelectProducts,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    quillEditor,
    vSelect,
    ImageDropzone,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      last_review: '',
      purchase_date: '',
      next_review: '',
      product: '',
      user: '',
      files: [],
      status: '',
      required,
      email,
      assetPrepare: false,
      location: '',
    }
  },
  computed: {
    ...mapGetters({
      localeDatePicker: 'languages/getLocaleDatePicker',
      selectStatus: 'assets/getSelectStatus',
      currentClient: 'clients/getCurrentClient',
      currentCompany: 'companies/getCurrentCompany',
      selectDepartments: 'departments/getSelectDepartments',
      selectLocations: 'locations/getSelectLocations',
      asset: 'assets/getAsset',
      currentRole: 'auth/getRole',
    }),
  },
  methods: {
    ...mapActions({
      edit: 'assets/edit',
      getAsset: 'assets/getAsset',
      getSelectStatus: 'assets/selectStatus',
      getSelectDepartments: 'departments/selectDepartments',
      getSelectLocations: 'locations/getSelectLocations',
    }),
    handleSubmit() {
      this.$refs.editAsset.validate().then(success => {
        if (success) {
          const formData = this.createFormData()
          if (formData) {
            this.edit({ id: this.asset.id, asset: formData })
          }
        }
      })
    },
    createFormData() {
      // const data = this.$refs.documents.getFormData('documents')
      const data = new FormData()
      // data.append('purchase_date', FlatPickrToTimestamp(this.purchase_date))

      data.append('name', this.asset.name)
      data.append('status_id', this.status.id)
      if (this.location) {
        data.append('origin_location_id', this.location.id)
        //data.append('location_id', this.location.id)
      }
      data.append('serial_number', this.asset.serial_number)
      data.append('pedido_num_id', this.asset.pedido_num_id)
      data.append('description', this.asset.description)
      data.append('coste', this.asset.coste)
      // data.append('product_id', this.product.id)

      return data
    },
    setData() {
      if (this.asset.last_review) {
        this.last_review = TimestampToFlatPickr(this.asset.last_review)
      }
      if (this.asset.next_review) {
        this.next_review = TimestampToFlatPickr(this.asset.next_review)
      }
      if (this.asset.purchase_date) {
        this.purchase_date = TimestampToFlatPickr(this.asset.purchase_date)
      }
      if (this.asset.documents.length > 0) {
        const aux = this
        this.asset.documents.forEach((element, index) => {
          const file = {
            name: `File ${index + 1}`,
            url: element.path,
            urlDelete: `/assets/delete_doc/${aux.asset.id}/${element.id}`,
          }
          this.files.push(file)
        })
      }
      if (this.asset.user) {
        this.user = this.asset.user
      }
      if (this.asset.status) {
        this.status = this.asset.status
      }
      if (this.asset.description === "null" || this.asset.description === null) {
        this.asset.description = '';
      }
      /*if (this.asset.location_relation) {
        this.location = this.asset.location_relation
      }*/
      if (this.asset.originLocation) {
        this.location = this.asset.originLocation
      }
      if (this.asset.product) {
        this.product = this.asset.product
      }
      this.assetPrepare = true
    },
  },
  async created() {
    await this.getAsset(this.$route.params.id)
    await this.setData()
    await this.getSelectStatus()
    await this.getSelectDepartments({ clients: [this.currentClient] })
    await this.getSelectLocations({ clients: [this.currentClient.id] })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.form-control:disabled{
  background-color: #f8f8f8 !important;
}
</style>
