import axios from "axios";
import { config } from "../shared/app.config";

const RESOURCE_NAME = "/events";
const RESOURCE_NAME_LOG = "/eventslogs";

export default {
  edit(id, event) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/${id}`, event);
  },
  delete(id) {
    return axios.delete(`${config.webApiBase + RESOURCE_NAME}/${id}`);
  },
  getEvent(id) {
    return axios.get(`${config.webApiBase + RESOURCE_NAME}/${id}`);
  },
  log(id, page, per_page, search = "", type = "", user_id = null, type_object = '') {
    return axios.post(`${config.webApiBase + RESOURCE_NAME_LOG}/${id}`, {
      page,
      per_page,
      search,
      type,
      user_id,
      type_object,
    });
  },

  logAll(
      idCompany = "",
      idClient = "",
      status = "",
      event_type = "",
      page,
      per_page,
      date_ini = "",
      date_fin = "",
      search = "",
      user_id = ""
  ) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME_LOG}/list`, {
      company_id: idCompany,
      client_id: idClient,
      status_asset_id: status,
      event_type,
      page,
      per_page,
      date_ini,
      date_fin,
      search,
      user_id,
    });
  },
};
