<template>
  <b-card>
    <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
      <h2 class="font-weight-bolder m-0">
        <span @click="$router.go(-1)">
          <b-button
            variant="white"
            class="btn-icon rounded-circle mr-50 bg-white"
          >
            <feather-icon icon="ChevronLeftIcon" />
          </b-button>
        </span>
        {{ $t("DetallesUsuario") }}
      </h2>
    </div>
    <!-- form -->
    <validation-observer ref="editUser">
      <b-form
        class="mt-2"
        @submit.prevent="handleSubmit"
        @keydown.enter="handleSubmit"
      >
        <b-row>
          <b-col cols="6" class="mb-2 auth-create-form">
            <ImageDropzone v-if="userPrepare" ref="images" :files="files" />
          </b-col>
          <b-col sm="6">
            <b-form-group :label="$t('Nombre')" label-for="account-name">
              <b-form-input
                v-model="user.name"
                name="name"
                :placeholder="$t('Nombre')"
              />
            </b-form-group>
            <b-form-group :label="$t('Apellidos')" label-for="account-surname">
              <b-form-input
                v-model="user.surname"
                :placeholder="$t('Apellidos')"
                name="surname"
              />
            </b-form-group>
            <validation-provider
              #default="{ errors }"
              :name="$t('Telefono')"
              rules="max:9|min:9"
            >
              <b-form-group :label="$t('Telefono')" label-for="account-phone">
                <b-input-group>
                  <template #prepend>
                    <vue-country-code
                      :key="codePhone"
                      :default-country="codePhone"
                      :disabled-fetching-country="true"
                      @onSelect="onSelect"
                    />
                  </template>

                  <b-form-input
                    v-model="phone"
                    type="number"
                    name="company"
                    :placeholder="$t('Telefono')"
                  />
                </b-input-group>
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="12">
            <b-form-checkbox
              class="mb-1 mt-1"
              id="emailTrue"
              name="check-button"
              v-model="not_email"
              inline
            >
              <span>{{ $t("NoEmail") }}</span>
            </b-form-checkbox>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              name="Email"
              :rules="requiredEmail"
            >
              <b-form-group :label="$t('Email')" label-for="account-e-mail">
                <b-form-input
                  v-model="user.email"
                  :disabled="disableEmail"
                  name="email"
                  :placeholder="$t('Email')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('Username')"
              :rules="requiredUsername"
            >
              <b-form-group
                :label="$t('Username')"
                label-for="account-username"
              >
                <b-form-input
                  v-model="user.username"
                  name="username"
                  :disabled="disableUsername"
                  :placeholder="$t('Username')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider #default="{ errors }" name="Dni">
              <b-form-group :label="$t('Dni')" label-for="account-dni">
                <b-form-input
                  v-model="user.dni"
                  name="dni"
                  :placeholder="$t('Dni')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('Rol')"
              rules="required"
            >
              <b-form-group :label="$t('Rol')" label-for="account-rols">
                <v-select
                  v-model="role"
                  label="display_name"
                  :filterable="false"
                  :searchable="false"
                  :options="selectRoles"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="6"
            v-if="
              currentRole == 'admin_empresa' ||
              currentRole == 'admin_cliente' ||
              currentRole == 'super_admin'
            "
          >
            <b-form-group :label="$t('Activado')" label-for="account-active">
              <b-form-checkbox
                class="mt-1"
                v-model="user.active"
                switch
                value="1"
              >
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col
            v-if="
              currentRole == 'admin_empresa' || currentRole == 'super_admin'
            "
            sm="6"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Compañía')"
              rules="required"
            >
              <b-form-group :label="$t('Compañía')" label-for="account-company">
                <v-select
                  v-model="companies"
                  label="name"
                  :filterable="false"
                  :searchable="false"
                  :options="selectCompanies"
                  :placeholder="$t('Compañía')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col
            v-if="
              currentRole == 'admin_empresa' || currentRole == 'super_admin'
            "
            sm="6"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Cliente')"
              :rules="
                role &&
                (role.name === 'admin_cliente' ||
                  role.name === 'operario_cliente' ||
                  role.name === 'prl_cliente')
                  ? 'required'
                  : ''
              "
            >
              <b-form-group :label="$t('Cliente')" label-for="account-clients">
                <v-select
                  v-model="clients"
                  label="name"
                  :filterable="false"
                  :searchable="false"
                  :options="selectClients"
                  multiple
                  :disabled="disabledClients"
                  :placeholder="$t('Cliente')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6" v-if="role && (role.name === 'operario_cliente')">
            <validation-provider
                #default="{ errors }"
                :name="$t('Solar')"
                rules="required"
            >
              <b-form-group
                  :label="$t('Solars')"
                  label-for="solar-product"
              >
                <SelectSolars v-model="solars" type="solar" multiple="true"/>
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col v-if="clients.length > 0" sm="12">
            <b-form-group label="Departamentos" label-for="account-departments">
              <div v-for="item in departmentsSelect" class="mt-2">
                <p class="mb-1">
                  <strong>{{ item.name }}</strong>
                </p>
                <b-row>
                  <b-col v-for="dep in item.data" cols="12" lg="3">
                    <b-form-checkbox
                      v-model="departments"
                      :value="dep.id"
                      plain
                    >
                      {{ dep.name }}
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6">
            <b-form-group>
              <b-form-checkbox v-model="changePassword" value="true" plain>
                <strong>{{ $t("CambiarContrasenya") }}</strong>
              </b-form-checkbox>
            </b-form-group>
            <b-form-group v-if="changePassword" label-for="account-password">
              <b-form-input
                v-model="password"
                name="password"
                :placeholder="$t('Contrasenya')"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12 text-right">
            <b-button type="submit" variant="primary" class="mt-2 mr-1">
              {{ $t("Guardar") }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import ImageDropzone from "@/components/elements/ImageDropzone/ImageDropzone.vue";
import vSelect from "vue-select";
import VueCountryCode from "vue-country-code-select";
import {BCol, BFormGroup} from "bootstrap-vue";
import SelectSolars from "@/components/selectSolars/SelectSolars.vue";

export default {
  components: {
    SelectSolars, BFormGroup, BCol,
    ImageDropzone,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    VueCountryCode,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      id: this.$route.params.id,
      companies: "",
      clients: [],
      solars: [],
      departments: [],
      files: [],
      profileFile: null,
      required,
      email,
      disableUsername: "",
      disableEmail: "",
      requiredEmail: "",
      requiredUsername: "",
      not_email: "",
      userPrepare: false,
      disabledClients: true,
      changePassword: false,
      password: "",
      role: {},
      codePhone: "",
      codePhoneSave: "",
    };
  },
  computed: {
    ...mapGetters({
      currentClient: "clients/getCurrentClient",
      user: "users/getUser",
      userSelectRoles: "users/getRoles",
      selectCompanies: "companies/getListCompanies",
      selectClients: "clients/getSelectClients",
      departmentsSelect: "departments/getSelectDepartments",
      currentRole: "auth/getRole",
    }),
    selectRoles() {
      const arrayOutput = [];
      this.userSelectRoles.forEach((element) => {
        if (element.name !== "admin_cliente") {
          arrayOutput.push(element);
        } else if (!this.not_email) {
          arrayOutput.push(element);
        }
      });
      return arrayOutput;
    },
    phone: {
      get() {
        if (
          this.user &&
          (this.user.phone == null || this.user.phone === "null")
        ) {
          return "";
        }
        return this.user.phone;
      },
      set(val) {
        this.user.phone = val;
        return val;
      },
    },
  },
  watch: {
    companies() {
      this.clients = [];

      if (this.companies) {
        this.searchClients({
          company: this.companies.id,
          client: this.currentClient,
        });
        this.disabledClients = false;
      } else {
        this.disabledClients = true;
      }
    },
    clients() {
      this.departments = [];
      const aux = this;
      if (this.clients.length > 0) {
        aux.selectDepartments({ clients: this.clients });
      } else {
        this.setSelectDepartments([]);
      }
    },
    not_email() {
      if (this.not_email) {
        this.disableUsername = false;
        this.disableEmail = true;
        this.requiredEmail = "";
        this.requiredUsername = "required";
      } else {
        this.disableUsername = true;
        this.disableEmail = false;
        this.requiredEmail = `required|email|emailUnique:${this.user.id}`;
        this.requiredUsername = "";
      }
    },
  },
  methods: {
    ...mapActions({
      edit: "users/edit",
      getUser: "users/getUser",
      searchCompanies: "companies/searchCompanies",
      searchClients: "clients/selectClients",
      selectDepartments: "departments/selectDepartments",
      getRoles: "users/getRoles",
    }),
    ...mapMutations({
      setSelectDepartments: "departments/setSelectDepartments",
    }),
    setData() {
      if (this.user) {
        if (
          this.user.avatar !== undefined &&
          this.user.avatar !== null &&
          this.user.avatar
        ) {
          const avatar = {
            name: "Avatar",
            url: this.user.avatar,
            urlDelete: `/users/delete_image/${this.user.id}`,
          };
          this.files = [avatar];
        }
        if (this.user.companies[0]) {
          this.companies = this.user.companies[0];
          this.searchClients(this.user.companies[0].id);
        }
        if (this.user.roles) {
          this.role = this.user.roles[0];
        }
        if (this.user.code_phone) {
          this.codePhone = JSON.parse(this.user.code_phone).iso;
        } else {
          this.codePhone = "ES";
          this.codePhoneSave = JSON.stringify({
            iso: "ES",
            code: 34,
          });
        }
        if (this.user.email) {
          this.disableUsername = true;
          this.disableEmail = false;
          this.requiredEmail = `required|email|emailUnique:${this.user.id}`;
          this.requiredUsername = "";
        } else {
          this.not_email = true;
          this.disableUsername = true;
          this.disableEmail = false;
          this.requiredEmail = "";
          this.requiredUsername = "required";
        }
        this.userPrepare = true;

        if (this.user.solars && this.user.solars.length > 0){
          this.solars = this.user.solars;
        }
      }
    },
    setClients() {
      if (this.user && this.user.clients) {
        this.clients = this.user.clients;
      }
    },
    setDepartments() {
      if (this.user && this.user.departments) {
        if (this.user.departments[0]) {
          this.user.departments.forEach((element) => {
            this.departments.push(element.id);
          });
        }
      }
    },
    handleSubmit() {
      this.$refs.editUser.validate().then((success) => {
        if (success) {
          const { id } = this.user;
          const formData = this.createFormData();
          if (formData) {
            this.edit({ id, user: formData });
          }
        }
      });
    },
    createFormData() {
      const data = this.$refs.images.getFormData();
      if (this.companies) {
        data.append("companies[]", this.companies.id);
      }
      if (this.departments.length > 0) {
        this.departments.forEach((element) => {
          if (element && element !== null) {
            data.append("departments[]", element);
          }
        });
      } else {
        data.append("departments", "");
      }
      if (this.clients.length > 0) {
        this.clients.forEach((element) => {
          data.append("clients[]", element.id);
        });
      } else {
        data.append("clients", "");
      }
      if(this.solars.length > 0){
        this.solars.forEach((element) => {
          data.append("solars[]", element.id);
        });
      }
      data.append("name", this.user.name);
      data.append("active", this.user.active);
      data.append("email", this.user.email);
      data.append("phone", this.phone);
      data.append("code_phone", this.codePhoneSave);
      data.append("surname", this.user.surname);
      data.append("lang", this.user.lang);
      data.append("username", this.user.username);
      data.append("not_email", this.not_email);
      data.append("dni", this.user.dni);

      if (this.changePassword === "true") {
        data.append("password", this.password);
      }

      if (this.role) {
        data.append("roles[]", this.role.id);
      }

      return data;
    },
    onSelect({ name, iso2, dialCode }) {
      this.codePhone = iso2;
      this.codePhoneSave = JSON.stringify({
        iso: iso2,
        code: dialCode,
      });
    },
  },
  async created() {
    await this.getUser(this.$route.params.id);
    await this.setData();
    await this.searchCompanies();
    await this.setClients();
    await this.setDepartments();
    await this.getRoles();
  },
  destroyed() {
    this.$store.commit("users/setUser", {});
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
