<template>
  <section class="app-ecommerce-details">
    <b-card v-if="product" no-body>
      <b-card-body>
        <b-row class="my-2">
          <!-- Left: Product Image Container -->
          <b-col cols="12" md="5" class="d-flex align-items-center justify-content-center mb-2 mb-md-0">
            <div class="d-flex align-items-center justify-content-center">
              <b-img
                  :src="product.imagen ? product.imagen : defaultImage"
                  :alt="`Image of ${product.name}`"
                  class="product-img"
                  fluid
              />
            </div>
          </b-col>

          <!-- Right: Product Details -->
          <b-col cols="12" md="7">
            <!-- Product Name -->
            <h4 class="font-weight-bolder">{{ product.name || 'Cargando...' }}</h4> <!-- Estado de carga -->
            <div v-if="product.documents && product.documents.length" class="mt-3">
              <p class="font-weight-bolder mb-50">
                {{ $t("documentacion") }}
              </p>
              <p v-for="document in product.documents" :key="document.id">
                <b-link :href="document.path" target="_blank" class="mr-1 mb-1 link">
                  {{ document.name }}
                </b-link>
              </p>
            </div>
            <p class="font-weight-bolder mt-2">
              {{ $t("ReviewType") }}
            </p>
            <p>{{ product.type_review ? $t(product.type_review.name) : 'Cargando...' }}</p> <!-- Manejo de carga -->
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- Lista de activos, epis, instalaciones y solar -->
    <ListAssets v-if="product.type === 'asset'" :productprop="this.$route.params.id" :showStadistics="false" />
    <ListEpis v-if="product.type === 'epi'" :productprop="this.$route.params.id" :showStadistics="false" />
    <ListInstallations v-if="product.type === 'installation'" :productprop="this.$route.params.id" :showStadistics="false" />
    <ListSolar v-if="product.type === 'solar'" :productprop="this.$route.params.id" :showStadistics="false" />


  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { BCard, BCardBody, BRow, BCol, BImg, BCardText, BLink, BFormSelect, BTable, BPagination, BBadge } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { config } from '@/shared/app.config'
import ListAssets from "@/views/assets/ListTable";
import ListEpis from "@/views/epis/ListTable";
import ListInstallations from "@/views/installations/ListTable";
import ListSolar from "@/views/solar_installation/ListTable";

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BCard,
    BImg,
    BCardBody,
    BCardText,
    BLink,
    BFormSelect,
    BPagination,
    BBadge,
    ListAssets,
    ListEpis,
    ListInstallations,
    ListSolar
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      defaultImage: require("@/assets/images/default/asset.png"),
      statusAssets: config.statusAssetsVariants,
      currentPage: 1,
      pageLength: 10,
      pages: ['10', '15', '25'],
      fields: [
        {
          label: this.$t('NombreProducto'),
          key: 'image',
        },
        {
          label: this.$t('Cliente'),
          key: 'client',
        },
        {
          label: this.$t('Estado'),
          key: 'status',
        },
        {
          label: this.$t('UltimaRevision'),
          key: 'last_review_date',
        },
        {
          label: this.$t('ProximaRevision'),
          key: 'next_review_date',
        },
        {
          label: this.$t('VidaUtil'),
          key: 'useful_life',
        },
        {
          label: this.$t('Acciones'),
          key: 'actions',
          class: 'text-right',
        },
      ],
    }
  },
  watch: {
    currentPage() {
      this.chargeData()
    },
    pageLength() {
      this.chargeData()
    },
  },
  computed: {
    ...mapGetters({
      currentClient: 'clients/getCurrentClient',
      product: 'products/getProduct',
      items: 'assets/getItems',
      totalItems: 'assets/getTotalItems',
    }),
  },
  methods: {
    ...mapActions({
      getProduct: 'products/getProduct',
      getListAssets: 'assets/getListAssets',
    }),
  },
  async created() {
    await this.getProduct(this.$route.params.id)
  },
}
</script>
<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
</style>
