import axios from "axios";
import { config } from "../shared/app.config";

const RESOURCE_NAME = "/tasks";

export default {
  edit(id, task) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/${id}`, task);
  },
  create(task) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}`, task);
  },
  getTask(id) {
    return axios.get(`${config.webApiBase + RESOURCE_NAME}/${id}`);
  },
  delete(id) {
    return axios.delete(`${config.webApiBase + RESOURCE_NAME}/${id}`);
  },
  getList(
    client = "",
    page,
    per_page,
    search,
    date_ini = "",
    date_fin = "",
    priority = "",
    status = "",
    orderBy = "",
    user_id = "",
    event_id = ""
  ) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/list`, {
      id_client: client,
      page,
      per_page,
      search,
      date_ini,
      date_fin,
      priority,
      status_task_id: status,
      orderBy,
      user_id,
      event_id,
    });
  },
  getListObject({
                  id,
                  per_page = '',
                  page = '',
                  search = '',
                  date_ini = '',
                  date_fin = '',
                  priority = '',
                  status = '',
                  orderBy = '',
                  user = '',
                  event_id = '',
                  type = 'asset'
                }) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/list/${type}/${id}`, {
      page,
      per_page,
      search,
      date_ini,
      date_fin,
      priority,
      status_task_id: status,
      orderBy,
      user_id: user,
      event_id,
    });
  }
};
