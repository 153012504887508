import router from "@/router";
import generalApi from "@/api/general-api";
import i18n from "@/libs/i18n";
import solarsApi from "../../api/solar-installations-api";

const state = {
  solar: {},
  items: [],
  selectStatus: [],
  selectSolars: [],
  totalItems: 0,
  summarySolarsClient: [],
};

const mutations = {
  setSolar(state, val) {
    state.solar = val;
  },
  setItems(state, val) {
    state.items = val;
  },
  setTotalItems(state, val) {
    state.totalItems = val;
  },
  setSelectStatus(state, val) {
    state.selectStatus = val;
  },
  setSelectSolars(state, val) {
    state.selectSolars = val;
  },
  setSummarySolarsClient(state, val) {
    state.summarySolarsClient = val;
  },
};

const actions = {
  async selectStatus({ commit }) {
    await generalApi.status("solar").then(
      (response) => {
        commit("setSelectStatus", response.data);
      },
      () => {
        commit(
          "notifications",
          {
            title: i18n.t("ErrorListado", { nombre: i18n.t("Estados") }),
            variant: "danger",
          },
          { root: true }
        );
        commit("setShowNotifications", true, { root: true });
      }
    );
  },
  async getSolar({ commit }, id) {
    commit("app/loading", true, { root: true });
    await solarsApi.getSolar(id).then(
      (response) => {
        if (response.data.data.archives) {
          if (response.data.data.archives.review_asset) {
            response.data.data.archives.review =
              response.data.data.archives.review_asset;
          }
          if (response.data.data.archives.review_epi) {
            response.data.data.archives.review =
              response.data.data.archives.review_epi;
          }
          if (response.data.data.archives.delivery_no_epi) {
            response.data.data.archives.delivery =
              response.data.data.archives.delivery_no_epi;
          }
          if (response.data.data.archives.delivery_epi) {
            response.data.data.archives.delivery =
              response.data.data.archives.delivery_epi;
          }
        }
        commit("setSolar", response.data.data);
        commit("app/loading", false, { root: true });
      },
      () => {
        commit("app/loading", false, { root: true });
        commit(
          "notifications",
          {
            title: i18n.t("ErrorGet", { nombre: i18n.t("Solar") }),
            variant: "danger",
          },
          { root: true }
        );
        commit("setShowNotifications", true, { root: true });
      }
    );
  },
  getListSolars(
    { commit },
    {
      client = "",
      page,
      per_page,
      search = "",
      category_id = "",
      province_id = "",
      status = "",
      vida_util = "",
      prox_revision = "",
      product_id = "",
      is_epi = "",
      user = "",
      date_ini,
      date_fin,
      location_id,
      num_albaran,
      subclient_id = "",
        solar_id = ''
    }
  ) {
    commit("app/loading", true, { root: true });
    solarsApi
      .getList(
        client,
        page,
        per_page,
        search,
        category_id,
        status,
        vida_util,
        prox_revision,
        product_id,
        is_epi,
        user,
        date_ini,
        date_fin,
        location_id,
        num_albaran,
        province_id,
        subclient_id,
          solar_id
      )
      .then(
        (response) => {
          response.data.data.forEach((element) => {
            if (element.archives) {
              if (element.archives.review_asset) {
                element.archives.review = element.archives.review_asset;
              }
              if (element.archives.review_epi) {
                element.archives.review = element.archives.review_epi;
              }
              if (element.archives.delivery_no_epi) {
                element.archives.delivery = element.archives.delivery_no_epi;
              }
              if (element.archives.delivery_epi) {
                element.archives.delivery = element.archives.delivery_epi;
              }
            }
          });
          commit("setItems", response.data.data);
          commit("setTotalItems", response.data.meta.total[1]);
          commit("app/loading", false, { root: true });
        },
        () => {
          commit("app/loading", false, { root: true });
          commit(
            "notifications",
            {
              title: i18n.t("ErrorListado", { nombre: i18n.t("Solars") }),
              variant: "danger",
            },
            { root: true }
          );
          commit("setShowNotifications", true, { root: true });
        }
      );
  },
  getListSummary({ commit }, { product_id }) {
    commit("app/loading", true, { root: true });
    solarsApi.getListSummary(product_id).then(
      (response) => {
        commit("setSummarySolarsClient", response.data);
        commit("app/loading", false, { root: true });
      },
      () => {
        commit("app/loading", false, { root: true });
        commit(
          "notifications",
          {
            title: i18n.t("ErrorCarga", { nombre: i18n.t("Dashboard") }),
            variant: "danger",
          },
          { root: true }
        );
        commit("setShowNotifications", true, { root: true });
      }
    );
  },
  async getSelectSolars({ commit }, { client = "" }) {
    await solarsApi.getList(client, 1, 9999999, "").then(
      (response) => {
        commit("setSelectSolars", response.data.data);
      },
      () => {
        commit(
          "notifications",
          {
            title: i18n.t("ErrorListado", { nombre: i18n.t("Solars") }),
            variant: "danger",
          },
          { root: true }
        );
        commit("setShowNotifications", true, { root: true });
      }
    );
  },
  edit({ commit }, { id, solar }) {
    commit("app/loading", true, { root: true });
    solarsApi.edit(id, solar).then(
      () => {
        commit(
          "notifications",
          {
            title: i18n.t("SuccessUpdateMaculino", {
              nombre: i18n.t("Solar"),
            }),
            variant: "success",
          },
          { root: true }
        );
        commit("setShowNotifications", true, { root: true });
        // commit("app/loading", false, { root: true });
        location.reload()
      },
      () => {
        commit("app/loading", false, { root: true });
        commit(
          "notifications",
          {
            title: i18n.t("ErrorUpdate", { nombre: i18n.t("Solar") }),
            variant: "danger",
          },
          { root: true }
        );
        commit("setShowNotifications", true, { root: true });
      }
    );
  },
  clone({ commit }, { idsolar, idlocation, quantity }) {
    commit("app/loading", true, { root: true });
    solarsApi.clone(idsolar, idlocation, quantity).then(
      () => {
        commit(
          "notifications",
          {
            title: i18n.t("SuccessCloned", { nombre: i18n.t("Solar") }),
            variant: "success",
          },
          { root: true }
        );
        commit("setShowNotifications", true, { root: true });
        commit("app/loading", false, { root: true });
        location.reload();
      },
      () => {
        commit("app/loading", false, { root: true });
        commit(
          "notifications",
          {
            title: i18n.t("ErrorCloned", { nombre: i18n.t("Solar") }),
            variant: "danger",
          },
          { root: true }
        );
        commit("setShowNotifications", true, { root: true });
      }
    );
  },
  create({ commit }, { solar }) {
    commit("app/loading", true, { root: true });
    solarsApi.create(solar).then(
      (response) => {
        commit(
          "notifications",
          {
            title: i18n.t("SuccessCreate", { nombre: i18n.t("Solar") }),
            variant: "success",
          },
          { root: true }
        );
        commit("setShowNotifications", true, { root: true });
        router.push({ name: "solars" });
        commit("app/loading", false, { root: true });
      },
      () => {
        commit("app/loading", false, { root: true });
        commit(
          "notifications",
          {
            title: i18n.t("ErrorCreate", { nombre: i18n.t("Solar") }),
            variant: "danger",
          },
          { root: true }
        );
        commit("setShowNotifications", true, { root: true });
      }
    );
  },
  delete({ commit }, id) {
    commit('app/loading', true, { root: true })
    solarsApi.delete(id)
      .then(
        () => {
          commit('notifications', { title: i18n.t('SuccessDelete', { nombre: i18n.t('Solar') }), variant: 'success' }, { root: true })
          location.reload()
        },
        (error) => {
          commit('app/loading', false, { root: true })
          if (error.response.data.error === 'This object is assigned to a task.') {
            commit('notifications', { title: i18n.t('ErrorDeleteSolarTaskAssigned'), variant: 'danger' }, { root: true })
          } else {
            commit('notifications', { title: i18n.t('ErrorDelete', { nombre: i18n.t('Solar') }), variant: 'danger' }, { root: true })
          }
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
};

const getters = {
  getSolar: (state) => state.solar,
  getItems: (state) => state.items,
  getTotalItems: (state) => state.totalItems,
  getSelectStatus: (state) => state.selectStatus,
  getSelectSolars: (state) => state.selectSolars,
  getListSummaryState: (state) => state.summarySolarsClient,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
