<template>
  <div>
    <b-card no-body class="mb-1">
      <div class="d-flex justify-content-between align-items-center px-1 py-1" v-if="showSolar === true && showAsset === true">
        <div>
          <h5 class="m-0 font-weight-bolder">
            {{ $t("Alertas") }}
          </h5>
        </div>
        <div class="d-flex align-items-center">
          <span
            class="ml-1 cursor-pointer"
            @click="visibleFilter = !visibleFilter"
          >
            <feather-icon icon="FilterIcon" size="20" />
          </span>
        </div>
      </div>
      <!-- FILTERS -->
      <div>
        <b-collapse id="collapse" v-model="visibleFilter" class="mt-2">
          <div class="px-2">
            <b-row>
              <b-col sm="4">
                <b-form-group
                  :label="$t('FechaInicio')"
                  label-for="filter-fechaini"
                >
                  <flat-pickr
                    v-model="activation_time"
                    @input="chargeData"
                    name="fecha_ini"
                    class="form-control"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="4">
                <b-form-group
                  :label="$t('FechaFin')"
                  label-for="filter-fechafin"
                >
                  <flat-pickr
                    v-model="deactivation_time"
                    @input="chargeData"
                    name="fecha_ini"
                    class="form-control"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="4">
                <b-container fluid p-0>
                  <b-form-group :label="$t('Nombre')" label-for="filter-search">
                    <b-row>
                      <b-col cols="8">
                        <b-form-input
                            v-model="searchTerm"
                            :placeholder="$t('Busqueda')"
                            type="text"
                            class="d-inline-block"
                        />
                      </b-col>
                      <b-col cols="4" style="padding-left: 0px">
                        <b-button variant="primary" @click="handleSearch">
                          {{ $t("Buscar") }}
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </b-container>
              </b-col>
              <b-col sm="4">
                <b-form-group :label="$t('Orden')" label-for="filter-order">
                  <v-select
                    v-model="orderby"
                    label="label"
                    :filterable="false"
                    :searchable="false"
                    :options="selectOrderBy"
                  >
                    <template slot="option" slot-scope="option">
                      {{ option.label }}
                      <feather-icon
                        v-if="option.type == 'asc'"
                        icon="ArrowUpIcon"
                        size="16"
                      />
                      <feather-icon
                        v-if="option.type == 'desc'"
                        icon="ArrowDownIcon"
                        size="16"
                      />
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      {{ option.label }}
                      <feather-icon
                        v-if="option.type == 'asc'"
                        icon="ArrowUpIcon"
                        size="16"
                      />
                      <feather-icon
                        v-if="option.type == 'desc'"
                        icon="ArrowDownIcon"
                        size="16"
                      />
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col sm="4">
                <b-form-group :label="$t('Severity')" label-for="filter-order">
                  <v-select
                    v-model="severity"
                    @input="chargeData"
                    label="name"
                    :filterable="false"
                    :searchable="false"
                    :options="selectSeverity"
                    multiple
                  >
                    <template slot="option" slot-scope="option">
                      {{ $t(option.label)  }}
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      {{ $t(option.label) }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col sm="4">
                <b-form-group :label="$t('Estado')" label-for="filter-order">
                  <v-select
                    v-model="status"
                    @input="chargeData"
                    label="name"
                    :filterable="false"
                    :searchable="false"
                    :options="selectStatus"
                    multiple
                  >
                    <template slot="option" slot-scope="option">
                      {{ $t(option.label) }}
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      {{ $t(option.label) }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col sm="4">
                <b-form-group :label="$t('Tipo')" label-for="filter-order">
                  <v-select
                      v-model="type"
                      @input="chargeData"
                      label="name"
                      :filterable="false"
                      :searchable="false"
                      :options="selectType"
                      multiple
                  >
                    <template slot="option" slot-scope="option">
                      {{ $t(option.label) }}
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      {{ $t(option.label) }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col sm="4" v-if="showSolar === true && showAsset === true">
                <b-form-group :label="$t('Instalaciones')" label-for="filter-order">
                  <SelectSolars v-model="solars" type="solar" :multiple="true" @input="chargeData"/>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </b-collapse>
      </div>
      <!-- VIEW ITEMS -->
      <b-table v-if="items && items.length" hover responsive :items="items" :fields="columns">
        <template #cell(activation_time)="data">
          {{ dateColumn(data.item.activation_time) }}
        </template>
        <template #cell(deactivation_time)="data">
          <span v-if="data.item.deactivation_time">
             {{ dateColumn(data.item.deactivation_time) }}
          </span>
        </template>
        <template #cell(severity)="data">
          <div class="mb-2 mt-1">
            <b-badge
                v-if="data.item.severity"
                :variant="statusPriorityVariants[data.item.severity]"
                class="mr-50"
            >
              {{ $t(data.item.severity) }}
            </b-badge>
          </div>
        </template>
        <template #cell(type)="data">
          <span v-if="data.item.type !== null">
            <b-badge
                variant="ligth"
                pill
                style="
                border: 1px solid lightgray;
                color: black;
                font-weight: 500;
              "
            >
              {{ $t(data.item.type) }}
            </b-badge>
          </span>
        </template>
        <template #cell(asset)="data">
          <b-link
              v-if="data.item.asset"
              :to="{ name: 'viewAsset', params: { id: data.item.asset.id } }"
              class="link"
          >
            <div class="d-flex align-items-center">
              <p class="d-inline-block m-0">{{ data.item.asset.name }}</p>
            </div>
          </b-link>
          <span v-else>{{ data.item.element_name }}</span>
        </template>
        <template #cell(solar)="data">
          <b-link
              v-if="data.item.solar"
              :to="{ name: 'viewSolar', params: { id: data.item.solar.id } }"
              class="link"
          >
            <div class="d-flex align-items-center">
              <p class="d-inline-block m-0">{{ data.item.solar.name }}</p>
            </div>
          </b-link>
        </template>
        <template #cell(status)="data">
          <div class="mb-2 mt-1">
            <b-badge
                v-if="data.item.status"
                :variant="statusAlertsVariants[data.item.status]"
                class="mr-50"
            >
              {{ $t(data.item.status) }}
            </b-badge>
          </div>
        </template>
      </b-table>
      <b-card v-else>
        <div class="text-center m-0 font-weight-bolder">
          <h5>{{ $t("No hay alertas") }}</h5>
        </div>
      </b-card>
    </b-card>
    <div :class="['d-flex align-items-center mb-3', showSolar === true && showAsset === true ? 'justify-content-between' : 'justify-content-center']" v-if="totalItems > 10">      <div class="d-flex align-items-center mb-0" v-if="showSolar === true && showAsset === true">
        <span class="text-nowrap"> {{ $t("PorPagina") }}: </span>
        <b-form-select
          v-model="pageLength"
          :options="pages"
          class="ml-50 mr-1"
          @input="handlePageChange"
        />
        <span class="text-nowrap"> {{ $t("Total") }}: {{ totalItems }}</span>
      </div>
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalItems"
          :per-page="pageLength"
          first-number
          last-number
          align="right"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
          @change="handleChangePage"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import store from '@/store/index'
import flatPickr from 'vue-flatpickr-component'
import {
  FlatPickrToTimestamp,
  TimestampToFlatPickr,
  TimestampToCustomFormat,
  ISOToCustomFormat,
  ISOToCustomFormatHour
} from '@/libs/helpers'
import vSelect from 'vue-select'
import { config } from '@/shared/app.config'
//import Stadisticsalerts from '@/views/alerts/Stadistics'
import axios from "axios";
import DownloadService from "@/shared/services/download-service";
import {BBadge, BLink} from "bootstrap-vue";
import SelectSolars from "@/components/selectSolars/SelectSolars.vue";

export default {
  components: {
    SelectSolars,
    BBadge,
    BLink,
    flatPickr,
    vSelect,
  },
  props: {
    user: {
      type: Object,
    },
    showSolar: {
      default: true,
    },
    showAsset: {
      default: true,
    },
    visibleFilter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      log: [],
      currentPage: 1,
      pageLength: 10,
      dir: false,
      responsable: "",
      pages: ["10", "15", "25"],
      orderby: {
        label: this.$t("FechaInicio"),
        column: "activation_time",
        type: "desc",
      },
      selectOrderBy: [
        {
          label: this.$t("FechaInicio"),
          column: "activation_time",
          type: "desc",
        },
        {
          label: this.$t("FechaInicio"),
          column: "activation_time",
          type: "asc",
        },
        {
          label: this.$t("FechaFin"),
          column: "deactivation_time",
          type: "desc",
        },
        {
          label: this.$t("FechaFin"),
          column: "deactivation_time",
          type: "asc",
        },
      ],
      selectStatus: config.statusAlerts,
      selectType: config.typeAlerts,
      statusAlertsVariants: config.statusAlertsVariants,
      statusPriorityVariants: config.statusPriorityVariants,
      selectSeverity: config.severity,
      activation_time: "",
      deactivation_time: "",
      severity: "",
      status: "",
      selectPriorities: config.priorities,
      columns: [
        ...(this.showSolar !== true || this.showAsset !== true ? [] : [{
          label: this.$t("Instalación"),
          key: "solar",
          thStyle: { minWidth: "11rem" },
        }]),
        ...(this.showAsset !== true ? [] : [{
          label: this.$t("Equipo"),
          key: "asset",
          thStyle: { minWidth: "11rem" },
        }]),
        {
          label: this.$t("Inicio"),
          key: "activation_time",
          thStyle: { minWidth: "3rem" },
        },
        {
          label: this.$t("Fin"),
          key: "deactivation_time",
          thStyle: { minWidth: "3rem" },
        },
        {
          label: this.$t("Alerta"),
          key: "name",
          thStyle: { minWidth: "15rem" },
        },
        {
          label: this.$t("Estado"),
          key: "status",
          thStyle: { maxWidth: "9rem" },
        },
        {
          label: this.$t("Severity"),
          key: "severity",
          thStyle: { maxWidth: "10rem" },
        },
        {
          label: this.$t("Tipo"),
          key: "type",
          thStyle: { maxWidth: "9rem" },
        },
      ],
      searchTerm: "",
      type: "",
      solars: ""
    };
  },
  computed: {
    ...mapGetters({
      currentClient: "clients/getCurrentClient",
      currentCompany: "companies/getCurrentCompany",
      items: "alerts/getItems",
      totalItems: "alerts/getTotalItems",
      dashboard: "actions/getDashboardData",
    }),
    dataToDo() {
      if (this.dashboard.alerts) {
        return this.dashboard.alerts.to_do;
      }
      return "";
    },
    dataInProgress() {
      if (this.dashboard.alerts) {
        return this.dashboard.alerts.in_progress;
      }
      return "";
    },
    dataComplete() {
      if (this.dashboard.alerts) {
        return this.dashboard.alerts.complete;
      }
      return "";
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  watch: {
    orderby() {
      this.chargeData();
    },
    responsable() {
      this.chargeData();
    },
    type() {
      this.chargeData();
    },
  },
  methods: {
    ...mapActions({
      list: "alerts/getListAlerts",
    }),
    ...mapMutations({
      loading: "app/loading",
    }),
    handleSearch() {
      this.currentPage = 1;
      this.chargeData();
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.chargeData();
    },
    handlePageChange() {
      this.currentPage = 1;
      this.chargeData();
    },
    onSortChange(params) {
      this.currentPage = 1;
      this.chargeData();
    },
    dateColumn(time) {
      if (time !== "null") {
        return ISOToCustomFormatHour(time);
      }
      return "";
    },
    chargeData() {
      let filterDateIni = "";
      let filterDateFin = "";
      if (this.activation_time) {
        filterDateIni = this.activation_time;
      }
      if (this.deactivation_time) {
        filterDateFin = this.deactivation_time;
      }
      const typeSelected = [];
      if (this.type.length > 0) {
        this.type.forEach((element) => {
          typeSelected.push(element.value);
        });
      }
      const severitySelected = [];
      if (this.severity.length > 0) {
        this.severity.forEach((element) => {
          severitySelected.push(element.value);
        });
      }
      const statusSelected = [];
      if (this.status.length > 0) {
        this.status.forEach((element) => {
          statusSelected.push(element.value);
        });
      }
      const solarsSelected = [];
      if (this.solars.length > 0) {
        this.solars.forEach((element) => {
          solarsSelected.push(element.id);
        });
      }
      const client =
        this.currentClient && this.currentClient !== null
          ? this.currentClient.id
          : ''

      /*await this.getDashboard({
        client,
        user_id: this.user ? this.user.id : null
      })*/
      let solar_id = ''
      if(this.showSolar != true){
        solar_id = this.showSolar
      }
      let asset_id = ''
      if(this.showAsset != true){
        asset_id = this.showAsset
      }

      this.list({
        client,
        page: this.currentPage,
        per_page: this.pageLength,
        search: this.searchTerm,
        date_ini: filterDateIni,
        date_fin: filterDateFin,
        severity: severitySelected,
        status: statusSelected,
        type: typeSelected,
        solars: solarsSelected,
        solar_id,
        asset_id,
        orderBy: this.orderby,
      });
    },
  },
  async mounted() {
    await this.chargeData()
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.dropdown-user {
  list-style-type: none;
}
[dir] .b-nav-dropdown .dropdown-toggle::after {
  display: none;
}
</style>
