var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{class:{'no-card-style': _vm.viewType !== 'VIEW'}},[_c('validation-observer',{ref:"createAsset"},[_c('b-form',{class:{'mt-2': _vm.viewType !== 'VIEW'},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-row',[(_vm.viewType === 'VIEW')?_c('b-col',{attrs:{"cols":"12"}},[_c('h4',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t("Añadiractivos"))+" ")])]):_vm._e(),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('NumeroSerie')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('NumeroSerie'),"label-for":"asset-serial_number"}},[_c('b-form-input',{attrs:{"name":"serial_number","placeholder":_vm.$t('NumeroSerie')},model:{value:(_vm.serial_number),callback:function ($$v) {_vm.serial_number=$$v},expression:"serial_number"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Tipo de producto'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Tipo de producto'),"label-for":"asset-type"}},[_c('SelectCategories',{attrs:{"type":"asset"},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('ProductoAsset'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('ProductoAsset'),"label-for":"asset-product"}},[_c('SelectProducts',{attrs:{"type":"asset","readonly":!_vm.category,"id_category":_vm.category && _vm.category.id},model:{value:(_vm.product),callback:function ($$v) {_vm.product=$$v},expression:"product"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(!_vm.isEditSolar)?_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Solar'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Solar'),"label-for":"asset-product"}},[_c('SelectSolars',{attrs:{"type":"solar"},model:{value:(_vm.solar),callback:function ($$v) {_vm.solar=$$v},expression:"solar"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1972566362)})],1):_vm._e(),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Estado'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Estado'),"label-for":"asset-status"}},[_c('v-select',{attrs:{"label":"alias","filterable":false,"searchable":false,"options":_vm.selectStatus,"placeholder":_vm.$t('Estado'),"readonly":false,"disabled":_vm.currentRole && (_vm.currentRole !== 'admin_cliente' && _vm.currentRole !== 'super_admin' && _vm.currentRole !== 'admin_empresa')},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(_vm.$t('status.assets.' + option.alias))+" ")]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(_vm.$t('status.assets.' + option.alias))+" ")]}}],null,true),model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('FechaCompraEquipo'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('FechaCompraEquipo'),"label-for":"asset-purchase_date"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"name":"purchase_date"},model:{value:(_vm.purchase_date),callback:function ($$v) {_vm.purchase_date=$$v},expression:"purchase_date"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('ProximaRevision'),"rules":_vm.prox_revision_required},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('ProximaRevision'),"label-for":"asset-next_review"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"name":"next_review","disabled":_vm.isDisabled},model:{value:(_vm.next_review),callback:function ($$v) {_vm.next_review=$$v},expression:"next_review"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('NumeroDeEquipos'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('NumeroDeEquipos'),"label-for":"num-equipos-revisiones"}},[_c('b-form-input',{attrs:{"name":"numero-equipos","type":"number","placeholder":_vm.$t('NumeroDeEquipos')},model:{value:(_vm.num_equipos),callback:function ($$v) {_vm.num_equipos=$$v},expression:"num_equipos"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Descripcion'),"label-for":"asset-description"}},[_c('quill-editor',{model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1),(_vm.viewType === 'VIEW')?_c('b-col',{attrs:{"cols":"12 text-right"}},[_c('b-button',{staticClass:"mt-2 mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t("Enviar"))+" ")])],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }