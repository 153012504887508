import axios from 'axios'
import { config } from '../shared/app.config'

const RESOURCE_NAME = '/sets'

export default {
  edit(id, set) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/${id}`, set)
  },
  create(set) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}`, set)
  },
  getSet(id) {
    return axios.get(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
  delete(id) {
    return axios.delete(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
  getList(client = '', page, per_page, search) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/list`, {
      id_client: client,
      page,
      per_page,
      search,
    })
  },
}
