import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { config } from "@/shared/app.config";
import {
  isUserLoggedIn,
  getUserData,
  getUserRole,
  getHomeRouteForLoggedInUser,
} from "@/auth/utils";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    { path: "/", redirect: { name: "login" } },
    {
      path: "/admin",
      meta: {
        needLogin: true,
      },
      beforeEnter: (to, from, next) => {
        store.dispatch("auth/checkToken");
        next();
      },
      component: () => import("@/layouts/full/LayoutFull.vue"),
      children: [
        {
          path: "",
          name: "dashboard",
          component: () => import("@/views/Home.vue"),
        },
        {
          path: "profile",
          name: "profile",
          component: () => import("@/views/account/AccountPage.vue"),
        },
        {
          path: "projects",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa"],
          },
          name: "projects",
          component: () => import("@/views/projects/List.vue"),
        },
        {
          path: "projects/edit/:id",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa"],
          },
          name: "editProject",
          component: () => import("@/views/projects/Edit.vue"),
        },
        {
          path: "projects/create",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa"],
          },
          name: "createProject",
          component: () => import("@/views/projects/Create.vue"),
        },
        {
          path: "categories",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa", "admin_cliente"],
          },
          name: "categories",
          component: () => import("@/views/categories/List.vue"),
        },
        {
          path: "categories/create",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa", "admin_cliente"],
          },
          name: "createCategory",
          component: () => import("@/views/categories/Create.vue"),
        },
        {
          path: "categories/edit/:id",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa", "admin_cliente"],
          },
          name: "editCategory",
          component: () => import("@/views/categories/Edit.vue"),
        },
        {
          path: "products",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa", "admin_cliente"],
          },
          name: "products",
          component: () => import("@/views/products/List.vue"),
        },
        {
          path: "products/view/:id",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa", "admin_cliente"],
          },
          name: "viewProduct",
          component: () => import("@/views/products/View.vue"),
        },
        {
          path: "products/edit/:id",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa", "admin_cliente"],
          },
          name: "editProduct",
          component: () => import("@/views/products/Edit.vue"),
        },
        {
          path: "products/create",
          meta: {
            needLogin: true,
            roleAllow: ["admin_cliente", "super_admin"],
          },
          name: "createProduct",
          component: () => import("@/views/products/Create.vue"),
        },
        {
          path: "events",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa"],
          },
          name: "events",
          component: () => import("@/views/events/List.vue"),
        },
        {
          path: "maintenance",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa", "admin_cliente"],
          },
          name: "maintenance",
          component: () => import("@/views/maintenance/List.vue"),
        },
        {
          path: "notifications",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa", "admin_cliente"],
          },
          name: "notifications",
          component: () => import("@/views/notifications/Manual.vue"),
        },
        {
          path: "events/edit/:id",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa"],
          },
          name: "editEvent",
          component: () => import("@/views/events/Edit.vue"),
        },
        {
          path: "forms",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa", "admin_cliente"],
          },
          name: "forms",
          component: () => import("@/views/forms/List.vue"),
        },
        {
          path: "forms/edit/:id",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa", "admin_cliente"],
          },
          name: "editForm",
          component: () => import("@/views/forms/Edit.vue"),
        },
        {
          path: "forms/create",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa", "admin_cliente"],
          },
          name: "createForm",
          component: () => import("@/views/forms/Create.vue"),
        },
        {
          path: "alerts",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa", "admin_cliente"],
          },
          name: "alerts",
          component: () => import("@/views/alerts/List.vue"),
        },
        {
          path: "tasks",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa", "admin_cliente"],
          },
          name: "tasks",
          component: () => import("@/views/tasks/List.vue"),
        },
        {
          path: "tasks/edit/:id",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa", "admin_cliente"],
          },
          name: "editTask",
          component: () => import("@/views/tasks/Edit.vue"),
        },
        {
          path: "tasks/view/:id",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa", "admin_cliente"],
          },
          name: "viewTask",
          component: () => import("@/views/tasks/View.vue"),
        },
        {
          path: "tasks/create",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa", "admin_cliente"],
          },
          name: "createTask",
          component: () => import("@/views/tasks/Create.vue"),
        },
        {
          path: "users",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa", "admin_cliente"],
          },
          name: "users",
          component: () => import("@/views/users/List.vue"),
        },
        {
          path: "users/view/:id",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa", "admin_cliente"],
          },
          name: "viewUser",
          component: () => import("@/views/users/View.vue"),
        },
        {
          path: "users/edit/:id",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa", "admin_cliente"],
          },
          name: "editUser",
          component: () => import("@/views/users/Edit.vue"),
        },
        {
          path: "users/create",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa", "admin_cliente"],
          },
          name: "createUser",
          component: () => import("@/views/users/Create.vue"),
        },
        //subclients
        {
          path: "subclients",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa", "admin_cliente"],
          },
          name: "subclients",
          component: () => import("@/views/subclients/List.vue"),
        },
        {
          path: "subclients/create",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa", "admin_cliente"],
          },
          name: "createSubClient",
          component: () => import("@/views/subclients/Create.vue"),
        },
        {
          path: "subclients/edit/:id",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa", "admin_cliente"],
          },
          name: "editSubClient",
          component: () => import("@/views/subclients/Edit.vue"),
        },
        {
          path: "subclients/view/:id?",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa", "admin_cliente"],
          },
          name: "viewSubClient",
          component: () => import("@/views/subclients/View.vue"),
        },
        //contact_person
        {
          path: "contact_person/create/:subclient_id",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa", "admin_cliente"],
          },
          name: "createContactPerson",
          component: () => import("@/views/contact_person/Create.vue"),
        },
        {
          path: "contact_person/view/:id",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa", "admin_cliente"],
          },
          name: "viewContactPerson",
          component: () => import("@/views/contact_person/View.vue"),
        },
        {
          path: "contact_person/edit/:id",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa", "admin_cliente"],
          },
          name: "editContactPerson",
          component: () => import("@/views/contact_person/Edit.vue"),
        },
        //departments
        {
          path: "departments",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa", "admin_cliente"],
          },
          name: "departments",
          component: () => import("@/views/departments/List.vue"),
        },
        {
          path: "departments/edit/:id",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa", "admin_cliente"],
          },
          name: "editDepartment",
          component: () => import("@/views/departments/Edit.vue"),
        },
        {
          path: "departments/create",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa", "admin_cliente"],
          },
          name: "createDepartment",
          component: () => import("@/views/departments/Create.vue"),
        },
        {
          path: "series",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa", "admin_cliente"],
          },
          name: "series",
          component: () => import("@/views/series/List.vue"),
        },
        {
          path: "series/create",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa", "admin_cliente"],
          },
          name: "createSerie",
          component: () => import("@/views/series/Create.vue"),
        },
        {
          path: "series/edit/:id",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa", "admin_cliente"],
          },
          name: "editSerie",
          component: () => import("@/views/series/Edit.vue"),
        },
        {
          path: "tags",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa", "admin_cliente"],
          },
          name: "tags",
          component: () => import("@/views/tags/List.vue"),
        },
        {
          path: "tags/create",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa", "admin_cliente"],
          },
          name: "createTag",
          component: () => import("@/views/tags/Create.vue"),
        },
        {
          path: "locations",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa", "admin_cliente"],
          },
          name: "locations",
          component: () => import("@/views/locations/List.vue"),
        },
        {
          path: "locations/edit/:id",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa", "admin_cliente"],
          },
          name: "editLocation",
          component: () => import("@/views/locations/Edit.vue"),
        },
        {
          path: "locations/create",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa", "admin_cliente"],
          },
          name: "createLocation",
          component: () => import("@/views/locations/Create.vue"),
        },
        {
          path: 'assets',
          meta: {
            needLogin: true,
            typeAvailable: 'assets',
            roleAllow: ['super_admin', 'admin_empresa', 'admin_cliente', 'prl_cliente'],
          },
          name: 'assets',
          component: () => import('@/views/assets/List.vue'),
        },
        {
          path: 'assets/edit/:id',
          meta: {
            needLogin: true,
            typeAvailable: 'assets',
            roleAllow: ['super_admin', 'admin_empresa', 'admin_cliente', 'prl_cliente'],
          },
          name: 'editAsset',
          component: () => import('@/views/assets/Edit.vue'),
        },
        {
          path: 'assets/create',
          meta: {
            needLogin: true,
            typeAvailable: 'assets',
            roleAllow: ['super_admin', 'admin_empresa', 'admin_cliente', 'prl_cliente'],
          },
          name: 'createAsset',
          component: () => import('@/views/assets/Create.vue'),
        },
        {
          path: 'assets/view/:id',
          meta: {
            needLogin: true,
            typeAvailable: 'assets',
            roleAllow: ['super_admin', 'admin_empresa', 'admin_cliente', 'prl_cliente'],
          },
          name: 'viewAsset',
          component: () => import('@/views/assets/View.vue'),
        },
        {
          path: "solar_installations",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa", "admin_cliente"],
          },
          name: "solars",
          component: () => import("@/views/solar_installation/List.vue"),
        },
        {
          path: "solar_installations/edit/:id",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa", "admin_cliente"],
          },
          name: "editSolar",
          component: () => import("@/views/solar_installation/Edit.vue"),
        },
        {
          path: "solar_installations/create/:subclient_id?",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa", "admin_cliente"],
          },
          name: "createSolar",
          component: () => import("@/views/solar_installation/Create.vue"),
        },
        {
          path: "solar_installations/view/:id",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa", "admin_cliente"],
          },
          name: "viewSolar",
          component: () => import("@/views/solar_installation/View.vue"),
        },
        {
          path: "sets",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa"],
          },
          name: "sets",
          component: () => import("@/views/sets/List.vue"),
        },
        {
          path: "sets/view/:id",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa", "admin_cliente"],
          },
          name: "viewSet",
          component: () => import("@/views/sets/View.vue"),
        },
        {
          path: "sets/edit/:id",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa"],
          },
          name: "editSet",
          component: () => import("@/views/sets/Edit.vue"),
        },
        {
          path: "sets/create",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa"],
          },
          name: "createSet",
          component: () => import("@/views/sets/Create.vue"),
        },
        {
          path: "clients",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa"],
          },
          name: "clients",
          component: () => import("@/views/clients/List.vue"),
        },
        {
          path: "clients/edit/:id",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa", "admin_cliente"],
          },
          name: "editClient",
          component: () => import("@/views/clients/Edit.vue"),
        },
        {
          path: "clients/view/:id?",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa", "admin_cliente"],
          },
          name: "viewClient",
          component: () => import("@/views/clients/View.vue"),
        },
        {
          path: "clients/create",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin", "admin_empresa"],
          },
          name: "createClient",
          component: () => import("@/views/clients/Create.vue"),
        },
        {
          path: "clients/import",
          meta: {
            needLogin: true,
            roleAllow: ["super_admin"],
          },
          name: "importClient",
          component: () => import("@/views/clients/Import.vue"),
        },
      ],
    },
    {
      beforeEnter: (to, from, next) => {
        if (to.query && to.query.lang) {
          let changeLocale = false;
          config.locales.forEach((e) => {
            if (e === to.query.lang) {
              changeLocale = true;
            }
          });
          if (changeLocale) {
            if (localStorage.currentLanguage) {
              let lang = localStorage.currentLanguage;
              lang = lang.replace('"', "");
              lang = lang.replace('"', "");
              if (lang !== to.query.lang) {
                store.commit("languages/setCurrentLanguage", to.query.lang);
                location.reload();
              }
            } else {
              store.commit("languages/setCurrentLanguage", to.query.lang);
            }
          }
        }
        next();
      },
      path: "/solar_installations/:id",
      name: "publicViewSolar",
      meta: {
        layout: "full",
        appMobile: true,
      },
      component: () => import("@/views/solar_installation/View.vue"),
    },
    {
      beforeEnter: (to, from, next) => {
        if (to.query && to.query.lang) {
          let changeLocale = false;
          config.locales.forEach((e) => {
            if (e === to.query.lang) {
              changeLocale = true;
            }
          });
          if (changeLocale) {
            if (localStorage.currentLanguage) {
              let lang = localStorage.currentLanguage;
              lang = lang.replace('"', "");
              lang = lang.replace('"', "");
              if (lang !== to.query.lang) {
                store.commit("languages/setCurrentLanguage", to.query.lang);
                location.reload();
              }
            } else {
              store.commit("languages/setCurrentLanguage", to.query.lang);
            }
          }
        }
        next();
      },
      path: "/products/:id",
      name: "publicViewProduct",
      meta: {
        layout: "full",
      },
      component: () => import("@/views/products/ViewPublic.vue"),
    },
    {
      beforeEnter: (to, from, next) => {
        if (to.query && to.query.lang) {
          let changeLocale = false;
          config.locales.forEach((e) => {
            if (e === to.query.lang) {
              changeLocale = true;
            }
          });
          if (changeLocale) {
            if (localStorage.currentLanguage) {
              let lang = localStorage.currentLanguage;
              lang = lang.replace('"', "");
              lang = lang.replace('"', "");
              if (lang !== to.query.lang) {
                store.commit("languages/setCurrentLanguage", to.query.lang);
                location.reload();
              }
            } else {
              store.commit("languages/setCurrentLanguage", to.query.lang);
            }
          }
        }
        next();
      },
      path: "/sets/:id",
      name: "publicViewSet",
      meta: {
        layout: "full",
      },
      component: () => import("@/views/sets/View.vue"),
    },
    {
      beforeEnter: (to, from, next) => {
        if (to.query && to.query.lang) {
          let changeLocale = false;
          config.locales.forEach((e) => {
            if (e === to.query.lang) {
              changeLocale = true;
            }
          });
          if (changeLocale) {
            if (localStorage.currentLanguage) {
              let lang = localStorage.currentLanguage;
              lang = lang.replace('"', "");
              lang = lang.replace('"', "");
              if (lang !== to.query.lang) {
                store.commit("languages/setCurrentLanguage", to.query.lang);
                location.reload();
              }
            } else {
              store.commit("languages/setCurrentLanguage", to.query.lang);
            }
          }
        }
        next();
      },
      path: "/tasks/:id",
      name: "publicViewTask",
      meta: {
        layout: "full",
        appMobile: true,
      },
      component: () => import("@/views/tasks/View.vue"),
    },
    {
      beforeEnter: (to, from, next) => {
        if (to.query && to.query.lang) {
          let changeLocale = false
          config.locales.forEach(e => {
            if (e === to.query.lang) {
              changeLocale = true
            }
          })
          if (changeLocale) {
            if (localStorage.currentLanguage) {
              let lang = localStorage.currentLanguage
              lang = lang.replace('"', '')
              lang = lang.replace('"', '')
              if (lang !== to.query.lang) {
                store.commit('languages/setCurrentLanguage', to.query.lang)
                location.reload()
              }
            } else {
              store.commit('languages/setCurrentLanguage', to.query.lang)
            }
          }
        }
        next()
      },
      path: '/assets/:id',
      name: 'publicViewAsset',
      meta: {
        layout: 'full',
        appMobile: true,
      },
      component: () => import('@/views/assets/View.vue'),
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        layout: "full",
        redirectIfLoggedIn: true,
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/error-401",
      name: "error-401",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn();

  if (to.meta.needLogin) {
    if (!isLoggedIn) {
      return next({ name: "login" });
    }
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn === true && isLoggedIn) {
    const userData = getUserData();
    next(getHomeRouteForLoggedInUser(userData));
  }
  /*
  if (to.meta.appMobile === true && navigator.userAgent !== "appmobile") {
    next({ name: "error-401" });
  }
  */
  if (to.meta.roleAllow) {
    const role = getUserRole();
    if (!to.meta.roleAllow.includes(role)) {
      next({ name: "error-401" });
    }
  }

  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
