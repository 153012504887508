const types = JSON.parse(localStorage.getItem('typesAvailables'))
const currentClient = JSON.parse(localStorage.getItem('currentClient'))

const output = [
  {
    title: "Inicio",
    route: "dashboard",
    icon: "HomeIcon",
  }
];

if (types) {
  if (types.solars) {
    output.push({
      title: "solars",
      route: "solars",
      icon: "LayersIcon",
    })
  }
  if (types.installations) {
    output.push({
      title: "installations",
      route: "installations",
      icon: "CpuIcon",
    })
  }
  if (types.assets) {
    output.push({
      title: "assets",
      route: "assets",
      icon: "DatabaseIcon",
    })
  }
  if (types.epis) {
    output.push({
      title: "epis",
      route: "epis",
      icon: "ServerIcon",
    })
  }
}

const empresaChildrens = [
  {
    title: "Usuarios",
    route: "users",
  },
  {
    title: "Departamentos",
    route: "departments",
  },
  {
    title: "Productos",
    route: "products",
  },
  {
    title: "Categorías",
    route: "categories",
  },
];
if (types) {
  if (types.installations ||  types.solars) {
    empresaChildrens.push({
      title: "Clientes",
      route: "subclients",
    })
    if(currentClient){
      if (currentClient.id == 8 || currentClient.id == 1 ) {
        output.push({
          title: "Informes",
          icon: "BarChartIcon",
          children: [
            {
              title: "Potencia/Irradiación",
              openModal: true,
              iframeUrl: "https://sungrow.fototrace.com/public-dashboards/a7170f6483364649b3aaad0a107be19b" // La URL que se quiere cargar en el iframe
            },
            {
              title: "Detalle Rendimiento",
              openModal: true,
              iframeUrl: "https://sungrow.fototrace.com/public-dashboards/bc01ea35fa9a40cd81c98263d7ff9e45"
            },
          ],
        }),
        output.push({
          title: "Alertas",
          route: "alerts",
          icon: "AlertTriangleIcon",
        })
      }
    }
  }
}

output.push(
    {
      title: "Tareas",
      route: "tasks",
      icon: "ClipboardIcon",
    },
    {
      title: "Actuaciones",
      route: "maintenance",
      icon: "CheckSquareIcon",
    },
    {
      title: "Etiquetas",
      icon: "TagIcon",
      children: [
        {
          title: "Etiquetas",
          route: "tags",
        },
        {
          title: "Series",
          route: "series",
        },
      ],
    },
    {
      title: "Gestión",
      icon: "SettingsIcon",
      children: empresaChildrens,
    },
);

export default output
