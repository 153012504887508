import generalApi from '@/api/general-api'
import i18n from '@/libs/i18n'

const state = {
  selectActions: [],
  dashboard: [],
  dashboardAssets: [],
  dashboardEpis: [],
  dashboardInstallations: [],
  dashboardSolars: [],
}

const mutations = {
  setSelectActions(state, val) {
    state.selectActions = val
  },
  setDashboardData(state, val) {
    state.dashboard = val
  },
  setDashboardAssets(state, val) {
    state.dashboardAssets = val
  },
  setDashboardEpis(state, val) {
    state.dashboardEpis = val
  },
  setDashboardInstallations(state, val) {
    state.dashboardInstallations = val
  },
  setDashboardSolars(state, val) {
    state.dashboardSolars = val
  },
}

const actions = {
  async getSelectActions({ commit }) {
    await generalApi.types('action')
        .then(
            response => {
              commit('setSelectActions', response.data)
            },
            () => {
              commit('notifications', { title: i18n.t('ErrorCarga', { nombre: i18n.t('Acciones') }), variant: 'danger' }, { root: true })
              commit('setShowNotifications', true, { root: true })
            },
        )
  },
  async getDashboardData({ commit }, {
    client = '',
    user_id = ''
  }) {
    commit('app/loading', true, { root: true })
    await generalApi.dashboard_comun({client, user_id})
        .then(
            response => {
              commit('setDashboardData', response.data)
              commit('app/loading', false, { root: true })
            },
            () => {
              commit('notifications', { title: i18n.t('ErrorCarga', { nombre: i18n.t('Dashboard') }), variant: 'danger' }, { root: true })
              commit('setShowNotifications', true, { root: true })
            },
        )
  },
  async getDashboardAssets({ commit }, {
    client = '',
    category = '',
    search = '',
    status = '',
    vida_util = '',
    prox_revision = '',
    product = '',
    user = '',
    date_ini = '',
    date_fin = '',
    location = '',
    num_albaran = '',
  }) {
    commit('app/loading', true, { root: true })
    await generalApi.dashboard_assets(client, category, search, status, vida_util, prox_revision, product, user, date_ini, date_fin, location, num_albaran)
        .then(
            response => {
              commit('setDashboardAssets', response.data)
              commit('app/loading', false, { root: true })
            },
            () => {
              commit('notifications', { title: i18n.t('ErrorCarga', { nombre: i18n.t('Dashboard') }), variant: 'danger' }, { root: true })
              commit('setShowNotifications', true, { root: true })
            },
        )
  },
  async getDashboardEpis({ commit }, {
    client = '',
    category = '',
    search = '',
    status = '',
    vida_util = '',
    prox_revision = '',
    product = '',
    user = '',
    date_ini = '',
    date_fin = '',
    location = '',
    num_albaran = '',
  }) {
    commit('app/loading', true, { root: true })
    await generalApi.dashboard_epis(client, category, search, status, vida_util, prox_revision, product, user, date_ini, date_fin, location, num_albaran)
        .then(
            response => {
              commit('setDashboardEpis', response.data)
              commit('app/loading', false, { root: true })
            },
            () => {
              commit('notifications', { title: i18n.t('ErrorCarga', { nombre: i18n.t('Dashboard') }), variant: 'danger' }, { root: true })
              commit('setShowNotifications', true, { root: true })
            },
        )
  },
  async getDashboardInstallations({ commit }, {
    client = '',
    category = '',
    search = '',
    status = '',
    vida_util = '',
    prox_revision = '',
    product = '',
    user = '',
    date_ini = '',
    date_fin = '',
    location = '',
    num_albaran = '',
  }) {
    commit('app/loading', true, { root: true })
    await generalApi.dashboard_installations(client, category, search, status, vida_util, prox_revision, product, user, date_ini, date_fin, location, num_albaran)
        .then(
            response => {
              commit('setDashboardInstallations', response.data)
              commit('app/loading', false, { root: true })
            },
            () => {
              commit('notifications', { title: i18n.t('ErrorCarga', { nombre: i18n.t('Dashboard') }), variant: 'danger' }, { root: true })
              commit('setShowNotifications', true, { root: true })
            },
        )
  },
  async getDashboardSolars({ commit }, {
    client = '',
    category = '',
    search = '',
    status = '',
    vida_util = '',
    prox_revision = '',
    product = '',
    user = '',
    date_ini = '',
    date_fin = '',
    location = '',
    num_albaran = '',
  }) {
    commit('app/loading', true, { root: true })
    await generalApi.dashboard_solars(client, category, search, status, vida_util, prox_revision, product, user, date_ini, date_fin, location, num_albaran)
        .then(
            response => {
              commit('setDashboardSolars', response.data)
              commit('app/loading', false, { root: true })
            },
            () => {
              commit('notifications', { title: i18n.t('ErrorCarga', { nombre: i18n.t('Dashboard') }), variant: 'danger' }, { root: true })
              commit('setShowNotifications', true, { root: true })
            },
        )
  },
}

const getters = {
  getSelectActions: state => { return state.selectActions },
  getDashboardData: state => { return state.dashboard },
  getDashboardAssets: state => { return state.dashboardAssets },
  getDashboardEpis: state => { return state.dashboardEpis },
  getDashboardInstallations: state => { return state.dashboardInstallations },
  getDashboardSolars: state => { return state.dashboardSolars },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
