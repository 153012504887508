import axios from "axios";
import { config } from "../shared/app.config";

const RESOURCE_NAME = "/alerts";

export default {
  getList(
    client = "",
    page,
    per_page,
    search,
    date_ini = "",
    date_fin = "",
    severity = "",
    status = "",
    type = "",
    solars = "",
    solar_id = "",
    asset_id = "",
    orderBy = "",
  ) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/list`, {
      id_client: client,
      page,
      per_page,
      search,
      date_ini,
      date_fin,
      severity,
      status,
      type,
      solars,
      solar_id,
      asset_id,
      orderBy,
    });
  },
};
