<template>
  <div class="pb-1">
    <b-table striped responsive :fields="fields" :items="items">
      <template #cell(created_at)="data">
        {{ toDate(data.item.created_at).toISOString().split('T')[0] }}
      </template>
      <template #cell(created_at_hour)="data">
        {{ toDate(data.item.created_at).toISOString().split('T')[1].split('.')[0] }}
      </template>
      <template #cell(actions)="data">
        <b-link v-if="data.item.documents.length > 0" @click="downloadDocuments(data.item.id)">
          <feather-icon icon="DownloadIcon" class="ml-50" />
        </b-link>
      </template>
      <template #cell(type)="data">
        {{ $t(`typeevents.${data.item.type}`) }}
      </template>
    </b-table>
    <div v-if="totalItems > 10" class="d-flex justify-content-center">
      <b-pagination
          v-model="currentPage"
          :total-rows="totalItems"
          :per-page="pageLength"
          first-number
          last-number
          align="right"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    type: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      currentPage: 1,
      pages: ["10", "15", "25"],
      pageLength: 10,
      fields: [
        { key: "created_at", label: this.$t("Fecha") },
        { key: "created_at_hour", label: this.$t("Hora") },
        { key: "type", label: this.$t("Evento") },
        { key: "responsible", label: this.$t("Responsable") },
        { key: "actions", label: this.$t("documentacion"), class: "text-right" },
      ],
    };
  },
  watch: {
    currentPage() {
      this.loadData();
    },
    pageLength() {
      this.loadData();
    },
  },
  computed: {
    ...mapGetters({
      items: "externalEvents/getItems",
      totalItems: "externalEvents/getTotalItems",
      currentClient: "clients/getCurrentClient",
    }),
  },
  methods: {
    ...mapActions({
      getList: "externalEvents/getListAsset",
      downloadDocuments: "externalEvents/downloadZipDocuments"
    }),
    toDate(f) {
      if (f) {
        return new Date(f);
      }
      return "";
    },
    loadData() {
      this.getList({
        asset_id: this.$route.params.id,
        page: this.currentPage,
        per_page: this.pageLength,
        search: this.searchTerm,
        type_object: this.type,
      });
    },
  },
  async mounted() {
    await this.loadData();
  },
}
</script>
