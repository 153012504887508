var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"createProduct"},[_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('h4',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t('Añadir Producto'))+" ")])]),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Nombre'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Nombre'),"label-for":"product-name"}},[_c('b-form-input',{attrs:{"name":"name","placeholder":_vm.$t('Nombre')},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Categoría'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Categoría'),"label-for":"product-category"}},[_c('SelectCategories',{model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Marca')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Marca'),"label-for":"product-marca"}},[_c('b-form-input',{attrs:{"name":"marca","placeholder":_vm.$t('Marca')},model:{value:(_vm.marca),callback:function ($$v) {_vm.marca=$$v},expression:"marca"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Modelo')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Modelo'),"label-for":"product-modelo"}},[_c('b-form-input',{attrs:{"name":"modelo","placeholder":_vm.$t('Modelo')},model:{value:(_vm.modelo),callback:function ($$v) {_vm.modelo=$$v},expression:"modelo"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Descripcion')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Descripcion'),"label-for":"product-description"}},[_c('quill-editor',{model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Imagen'),"label-for":"product-image"}},[_c('ImageDropzone',{ref:"image",attrs:{"files":_vm.imagen,"max-files":"1"}})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Documentos'),"label-for":"documents"}},[_c('ImageDropzone',{ref:"documents",attrs:{"files":_vm.documents}})],1)],1),_c('b-col',{attrs:{"cols":"12 text-right"}},[_c('b-button',{staticClass:"mt-2 mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('Guardar'))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }